import React, { useEffect, useContext, useState, useRef } from "react";
import styled from "styled-components";
import exit from "../../../assets/exit.png";
import { useNavigate } from "react-router-dom";
import { urlUserLogin, urlUserForgotPassword } from "../../../helpers/url";
import { useAuth } from "../../../hooks/useAuth";
import axios from "axios";
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 5;
    @media (max-width: 767px) {
        margin-top: 40px;
    }
`;
const Header = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 28.8px;
    align-items: center;
    @media (max-width: 767px) {
        padding: 18.63px;
    }
`;
const TextHeading = styled.h2`
    padding: 0px;
    margin: 28.8px 0px 0px;
    font-weight: 700;
    font-size: 25.3125px;
    color: #f3f3f8;
    @media (max-width: 767px) {
        margin: 18.63px 0px 0px;
    }
`;
const Exit = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35.443125px;
    height: 35.443125px;
    cursor: pointer;
    position: absolute;
    z-index: 6;
    top: 14.4px;
    right: 14.4px;
    border-radius: 10px;
    border: solid 2px rgba(237, 236, 242, 0.5);
    @media (max-width: 767px) {
        display: none;
    }
`;
const PasswordContainer = styled.div`
    width: 100%;
    height: 43.2px;
`;
const Input = styled.input`
    outline: none;
    width: 100%;
    height: 43.678125px;
    border-radius: 21.6px;
    font-family: "Nunito";
    font-weight: 400;
    color: #f3f3f8;
    background-color: #4d4b83;
    text-indent: 15px;
    margin-bottom: 14.4px;
    font-size: 12.65625px;
    border: ${(props) =>
        props.hasError == "error"
            ? `solid 1.5px #F47060`
            : `solid 1.5px #4d4b83`};
    ::placeholder {
        color: rgba(243, 243, 248, 0.5);
    }
`;
const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 43.2px;
    margin-top: 28.8px;
    width: 154.36125px;
    height: 43.2px;
    font-size: 13.921875px;
    font-family: Nunito;
    font-weight: 400;
    color: #f3f3f8;
    background-color: #9d9cbd;
    border-radius: 21.6px;
    cursor: pointer;
    @media (max-width: 767px){
        margin-top: 205px;
    }
`;
const Image = styled.img`
    width: 14.23125px;
    height: 14.23125px;
`;
const ForgotPwContainer = styled.div`
    display: flex;
    width: 100%;
    font-size: 13.921875px;
    color: rgba(243, 243, 248, 0.5);
`;
const BtnContainer = styled.div`
    width: 40%;
    height: 20px;
    line-height: 20px;
    margin-top: 14.4px;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-right: 28.8px;
    @media (max-width: 767px) {
        margin-bottom: 9.315px;
        padding-right: 18.63px;
    }
`;
const ForgotPw = styled.div`
    cursor: pointer;
`;
const ForgotPwInfo = styled.div`
    width: 60%;
    box-sizing: border-box;
    padding-left: 28.8px;
    @media (max-width: 767px) {
        padding-left: 18.63px;
    }
`;
const InfoContainer = styled.div`
    font-size: 11.390625px;
    margin-top: 28.8px;
    color: #f3f3f8;
    font-family: "Lato";
    font-weight: 300;
    @media (max-width: 767px) {
        margin-top: 18.63px;
    }
`;
const SignUpButton = styled.div`
    color: rgba(237, 236, 242, 0.5);
    font-family: "Nunito";
    font-size: 13.921875px;
    margin-top: 7.2px;
    cursor: pointer;
`;
const TextParagraph = styled.p`
    font-size: 13.921875px;
    font-family: Nunito;
    font-weight: 400;
    color: rgba(243, 243, 248, 0.5);
`;
const EmailSpan = styled.span`
    color: #f3f3f8;
`;
const Login = ({ exitHandler, signupHandler, setLoginHeight, userEmailAlreadyExists }) => {
    const refEmail = useRef();
    const refPassword = useRef();
    const [info, setInfo] = useState("");
    const [inputs, setInputs] = useState({
        email: { value: userEmailAlreadyExists.length > 0 ? userEmailAlreadyExists : "", error: "" },
        password: { value: "", error: "" },
    });
    const { login } = useAuth();
    const [hasDirtyFields, setHasDirtyFields] = useState(false);
    const [isPasswordForgotten, setIsPasswordForgotten] = useState(false);
    const [status, setStatus] = useState();
    const [statusText, setStatusText] = useState("");
    const navigate = useNavigate();
    const forgotPasswordHandler = (boolean) => {
        setIsPasswordForgotten((prev) => boolean);
    };
    const loginHandler = async (e) => {
        e.preventDefault();
        console.log("response", true);
        try {
            const response = await axios.post(urlUserLogin, {
                email: inputs.email.value.toLowerCase(),
                password: inputs.password.value,
            });
            if (response.status == 202) {
                setInputs((values) => ({
                    email: {
                        value: values.email.value.toLowerCase(),
                        error: "no data",
                    },
                    password: {
                        value: values.password.value,
                        error: "",
                    },
                }));
            }
            if (response.status == 201) {
                setInputs((values) => ({
                    email: {
                        value: values.email.value.toLowerCase(),
                        error: "",
                    },
                    password: {
                        value: values.password.value,
                        error: "",
                    },
                }));
                login(response.data.accessToken);
                navigate("/account", { replace: true });
            }
            console.log("response", response);
        } catch (error) {
            console.log("error", error);
        }
    };
    const forgotHandler = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(urlUserForgotPassword, {
                email: inputs.email.value.toLowerCase(),
            });
            if (response.status == 204) {
                setInputs((values) => ({
                    email: {
                        value: values.email.value.toLowerCase(),
                        error: "no data",
                    },
                    password: {
                        value: values.password.value,
                        error: "",
                    },
                }));
                setHasDirtyFields(false);
            }
            if (response.status == 200) {
                setStatus(response.status);
                setStatusText(response.data.message);
                setHasDirtyFields(false);
            }
        } catch (err) {
            console.log("error", err.response);
        }
    };
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        let msg = {
            error: "",
            value: value,
        };
        setHasDirtyFields(true);
        setInputs((values) => ({
            ...values,
            [name]: msg,
        }));
    };
    const errorHandler = (err) => {
        if (err == "no data") return "error";
    };
    const handleKeydown = (e) => {
        if(e.key === "Enter"){
            if (e.target.name === "email"){
                refPassword.current.focus();
            }
            if(e.target.name === "password"){
                loginHandler(e);
            }
        }
    };
    useEffect(() => {
        if (status == 200 && statusText == "OK") {
            setLoginHeight(215);
            setTimeout(() => {
                setInputs({
                    email: { value: "", error: "" },
                    password: { value: "", error: "" },
                });
                setIsPasswordForgotten(false);
                setHasDirtyFields(false);
                exitHandler();
            }, 5000);
        }
    }, [status]);
    return (
        <Wrapper>
            <Header>
                <Exit onClick={exitHandler}>
                    <Image src={exit} />
                </Exit>
                <TextHeading>
                    {isPasswordForgotten ? "Glömt lösenord" : "Logga in"}
                </TextHeading>
            </Header>
            <Content>
                {status == 200 && statusText == "OK" ? (
                    <TextParagraph>
                        Ett email har skickats till {inputs.email.value}, klicka på
                        länken i mejlet för att byta lösenord.
                    </TextParagraph>
                ) : (
                    <>
                        <Input
                            ref={refEmail}
                            type="text"
                            placeholder="E-post"
                            name="email"
                            value={inputs.email.value}
                            onChange={handleChange}
                            hasError={errorHandler(inputs.email.error)}
                            autocomplete="off"
                            onKeyDown={handleKeydown}
                        />
                        <PasswordContainer>
                            {isPasswordForgotten ? null : (
                                <Input
                                    ref={refPassword}
                                    type="password"
                                    placeholder="Lösenord"
                                    name="password"
                                    value={inputs.password.value}
                                    onChange={handleChange}
                                    hasError={errorHandler(inputs.email.error)}
                                    autocomplete="off"
                                    onKeyDown={handleKeydown}
                                />
                            )}
                        </PasswordContainer>
                        <ForgotPwContainer>
                            <ForgotPwInfo> {info} </ForgotPwInfo>
                            <BtnContainer>
                                {isPasswordForgotten ? null : (
                                    <ForgotPw
                                        onClick={(e) =>
                                            forgotPasswordHandler(true)
                                        }
                                    >
                                        Glömt lösenord
                                    </ForgotPw>
                                )}
                            </BtnContainer>
                        </ForgotPwContainer>
                        <Button
                            onClick={
                                hasDirtyFields
                                    ? isPasswordForgotten
                                        ? forgotHandler
                                        : loginHandler
                                    : () => console.log("no data")
                            }
                        >
                            {isPasswordForgotten ? "Skicka" : "Logga in"}
                        </Button>
                        <InfoContainer>
                            {isPasswordForgotten
                                ? "Vill du logga in?"
                                : "Har du inget konto på Danzpunkten?"}
                        </InfoContainer>
                        <SignUpButton
                            onClick={
                                isPasswordForgotten
                                    ? (e) => forgotPasswordHandler(false)
                                    : signupHandler
                            }
                        >
                            Klicka här
                        </SignUpButton>
                    </>
                )}
            </Content>
        </Wrapper>
    );
};

export default Login;
