import React from 'react';
import styled from 'styled-components';
import logo from "../../assets/logo.png";
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #f3f3f8;`;
const StatusContainer = styled.div`
    position: fixed;
    top: 0;
    min-width: 100%;
    width: 100%;
    max-width: 1440px;
    height: 43.2px;
    background-color: #34326a;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    z-index: 2;
    @media (max-width: 767px) {
        height: 55.806px;
    }
`;
const LogoContainer = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Logo = styled.img`
    width: 158.9px;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 1440px;
    height: auto;
    background-color: #f3f3f8;
    margin: 0 auto;
    margin-top: 42.2px;
    @media (max-width: 767px) {
        width: 100%;
        margin: 0px;
    }
`;
const TextHeading = styled.h2`
    padding: 0px;
    margin: 28.8px 0px 0px;
    font-weight: 700;
    font-size: 25.3125px;
    color: #66659c;
`;
const UnderHeading = styled.p`
    margin-top: 28.8px;
    margin-bottom: 0px;
    padding: 0px;
    color: #4d4d4d;
    font-size: 19.805625px;
    font-weight: 400;
    height: auto;
    
`;
const TextParagraphHeading = styled.p`
margin-bottom: 14.4px;
margin-top:14.4px;
padding:0px;
`;
const TextParagraph = styled.p`
    margin: 0px;
    padding: 0px;
    color: #4d4d4d;
    font-size: 17.510625px;
    font-weight: 400;
    height: auto;
    @media (max-width: 767px) {
        font-size: 14.510625px;
    }
`;
const Ul = styled.ul`
    margin-top: 7.2px;
    margin-left: 28.8px;
    margin-bottom: 0px;
    margin-right: 0px;
`;
const BottomPadding = styled.div`
    padding-bottom: 150px;
`;
const PrivacyPolicy = () => {
    return (
        <Wrapper>
            <StatusContainer>
                <LogoContainer>
                    <Logo src={`${logo}`} />
                </LogoContainer>
            </StatusContainer>
            <Container>
                <TextParagraph>
                Läs DANZPUNKTENS INTEGRITETSPOLICY som <a href ="https://danzpunkten.se/policy.pdf">pdf</a> eller läs nedan.
                </TextParagraph>
                <TextHeading>
                    DANZPUNKTENS INTEGRITETSPOLICY
                </TextHeading>
                <UnderHeading>
                    1. ALLMÄNT
                </UnderHeading>
                <TextParagraphHeading>
                    1.1.
                </TextParagraphHeading>
                <TextParagraph>
                    Danzpunkten är en digital plattform som ger dig (hädanefter "Du" eller "Användaren" i någon
                    form) tillgång till Danzpunktens app, där användaren får söka och sortera ut kommande
                    dansevenemang i Danzpunktens digitala dansbibliotek (hädanefter "Appen” i någon form).
                    Appen tillhandahålls av Acezux Systems Dev. Handelsbolag, organisationsnummer
                    969777-3472, ("Danzpunkten" eller "Vi" i någon form).
                </TextParagraph>
                <TextParagraphHeading>
                    1.2.
                </TextParagraphHeading>
                <TextParagraph>
                    Detta dokument beskriver Danzpunktens insamling, lagring, användning och spridning av viss
                    information, inklusive dina personuppgifter i samband med att vi tillhandahåller en tjänst.
                    Integritetspolicy är också tillämplig på personer som besöker vår hemsida eller laddar ner och
                    använder vår app. Den syftar till att informera dig om vår behandling av dina personuppgifter
                    och säkerställa att dina personuppgifter behandlas med respekt och i enlighet med tillämplig
                    dataskyddslagstiftning när du använder appen.
                </TextParagraph>
                <TextParagraphHeading>
                    1.3.
                </TextParagraphHeading>
                <TextParagraph>
                    Danzpunkten har vidtagit lämpliga tekniska och organisatoriska säkerhetsåtgärder för att
                    säkerställa att dina personuppgifter är skyddade från obehörig åtkomst eller röjande,
                    användning, modifiering, förstöring och förlust. För det fall du inte är bekväm med innehållet i
                    denna integritetspolicy, kan du alltid välja att avstå från att använda appen.
                </TextParagraph>
                <UnderHeading>
                    2. PERSONUPPGIFTSANSVARIG
                </UnderHeading>
                <TextParagraphHeading>
                    2.1.
                </TextParagraphHeading>
                <TextParagraph>
                    Danzpunkten är personuppgiftsansvarig för behandling av dina personuppgifter som utförs av
                    Danzpunkten. Du har rätt att kontakta Danzpunkten vid frågor angående till exempel
                    integritetsskydd. Information om hur du kontaktar oss finns i avsnitt 12.
                </TextParagraph>
                <UnderHeading>
                    3. NÄR OCH VARIFRÅN SAMLAR DANZPUNKTEN IN
                    PERSONUPPGIFTER?
                </UnderHeading>
                <TextParagraphHeading>
                    3.1.
                </TextParagraphHeading>
                <TextParagraph>
                    Danzpunkten samlar in personuppgifter om dig när:
                    <Ul>
                        <li>du besöker vår hemsida</li>
                        <li>du skapar ett konto hos på vår hemsida,</li>
                        <li>du installerar och/eller använder vår app,</li>
                        <li>du anmäler fel, intrång eller på annat sätt olämpligt material eller av annan anledning</li>
                        kontaktar oss,
                        <li>du kopplar appen till ditt Facebookkonto eller någon annan liknande tredjepartstjänst
                            varvid vi mottar personuppgifter från tredjepartstjänsten,</li>
                        <li>du besvarar en undersökning, inklusive men inte begränsat till kundnöjdhetsenkäter eller
                            marknadsundersökningar eller svarar på kommunikation från oss, eller
                            det i övrigt är nödvändigt för att administrera relationen mellan dig och Danzpunkten.</li>
                    </Ul>
                </TextParagraph>
                <TextParagraphHeading>
                    3.2.
                </TextParagraphHeading>
                <TextParagraph>
                    Vi samlar även in information genom våra egna eller tredjeparts cookies och liknande
                    uppföljningstekniker (inklusive men inte begränsat till web beacons, taggar och pixlar) som kan
                    komma att logga dina aktiviteter och val, d v s när du laddar ner vår app, använder tjänsten eller
                    besöker vår hemsida. Detta sker t e x i syfte att förenkla inloggning, komma ihåg dina
                    inställningar, göra anpassade marknadsföringsutskick eller mäta hur framgångsrik
                    marknadsföringen är. För mer information om vår användning av cookies kontakta Danzpunkten
                    på info@danzpunkten.se.
                </TextParagraph>
                <UnderHeading>
                    4. VILKA PERSONUPPGIFTER SAMLAR DANZPUNKTEN IN?
                </UnderHeading>
                <TextParagraphHeading>
                    4.1.
                </TextParagraphHeading>
                <TextParagraph>
                    När du skapar ett konto hos Danzpunktens hemsida eller kontaktar Danzpunkten samlar
                    Danzpunkten in personuppgifter om dig. Grundläggande användardata som Danzpunkten
                    samlar in inkluderar dina kontaktuppgifter såsom ditt namn och e-postadress. Danzpunkten kan
                    också komma att samla in personuppgifter om födelsedatum, dansroll, kön och ytterligare
                    kontaktuppgifter såsom din adress.
                </TextParagraph>
                <TextParagraphHeading>
                    4.2.
                </TextParagraphHeading>
                <TextParagraph>
                    När du använder appen, besöker Danzpunktens hemsida eller använder andra av våra tjänster
                    kan vi komma att inhämta uppgifter rörande användning (såsom valda dansevenemang och
                    gjorda sökningar), visningsinformation (såsom innehåll som har visats) och teknisk data (såsom
                    unika plattformsidentifikatorer, telefon- och plattformsversioner, enheters IP-adresser,
                    Danzpunktens app-version, URL-information, lösenord (krypterade), cookies och typ av
                    webbläsare).
                </TextParagraph>
                <TextParagraphHeading>
                    4.3.
                </TextParagraphHeading>
                <TextParagraph>
                    Om du väljer att koppla appen till Facebook eller andra liknande tredjepartstjänster som
                    ansvarar för personuppgifter, kan Danzpunkten komma att samla in och behandla sådana
                    personuppgifter som du har givit Facebook, eller annan sådan tredjepart, tillåtelse att dela med
                    sig av till Danzpunkten. Danzpunkten uppmuntrar dig att ta del av sådana tredjeparters
                    integritetspolicys.
                </TextParagraph>
                <TextParagraphHeading>
                    4.4.
                </TextParagraphHeading>
                <TextParagraph>
                    Danzpunkten kan också komma att samla in och behandla personuppgifter som du
                    tillhandahåller i publika forum på Danzpunktens hemsida eller tillgängliggör för Danzpunkten när
                    du använder vår hemsida, Danzpunktens sidor på tredjepartsplattformar såsom
                    marknadsplatser för appar, sociala medier eller när du länkar din profil på en
                    tredjepartssida/plattform med ditt personliga konto i appen.
                </TextParagraph>
                <TextParagraphHeading>4.5.</TextParagraphHeading>
                <TextParagraph>Danzpunkten kan också komma att inhämta och behandla vissa personuppgifter relaterade till
                    användarbehörighet för appen.</TextParagraph>
                <UnderHeading>5. UNDER HUR LÅNG TID SPARAS UPPGIFTERNA?</UnderHeading>
                <TextParagraphHeading>5.1.</TextParagraphHeading>
                <TextParagraph>Danzpunkten sparar dina personuppgifter så länge det är nödvändigt med hänsyn till
                    ändamålen med respektive behandling.
                </TextParagraph>
                <TextParagraphHeading>5.2.</TextParagraphHeading>
                <TextParagraph>
                    Detta innebär att personuppgifter som samlas in och behandlas för marknadsföringssyften
                    sparas så länge du har ett konto hos Danzpunkten och upp till tolv (12) månader efter att ditt
                    konto avslutats om du inte givit Danzpunkten ditt samtycke att fortsätta behandlingen av sådana
                    uppgifter.
                </TextParagraph>
                <TextParagraphHeading>5.3.</TextParagraphHeading>
                <TextParagraph>
                    När du kontaktar Danzpunkten för att erhålla support eller av andra anledningar, kommer vi att
                    lagra dina personuppgifter under tjugofyra (24) månader efter att vi utförde din sista
                    supportaktivitet (t ex felanmälan och begäran).
                </TextParagraph>
                <TextParagraphHeading>5.4.</TextParagraphHeading>
                <TextParagraph>
                    Dina personuppgifter lagras för analytiska ändamål under tiden som du har ett aktivt konto hos
                    Danzpunkten och under tjugofyra (24) månader därefter.
                </TextParagraph>
                <TextParagraphHeading>5.5.</TextParagraphHeading>
                <TextParagraph>
                    Danzpunkten kan komma att spara personuppgifter under längre perioder än de som nämns
                    ovan om sådan skyldighet följer av lag eller i syfte att fastställa, utöva eller försvara rättsliga
                    anspråk.
                </TextParagraph>
                <UnderHeading>6. VARFÖR BEHANDLAR DANZPUNKTEN DINA PERSONUPPGIFTER?</UnderHeading>
                <TextParagraphHeading>6.1.</TextParagraphHeading>
                <TextParagraph>
                    Danzpunkten behandlar dina personuppgifter för flera olika syften. Huvudsakligen behandlar
                    Danzpunkten dina personuppgifter i syfte att hantera kundrelationen med dig och för att fullgöra
                    sina rättsliga förpliktelser. Dina personuppgifter kan även komma att behandlas i marknads- och
                    kundanalyser, marknadsundersökningar, statistik, affärsövervakning samt affärs- och
                    metodutveckling av Danzpunkten i syfte att utveckla och anpassa appen och dess funktioner.
                </TextParagraph>
                <TextParagraphHeading>6.2.</TextParagraphHeading>
                <TextParagraph>
                    Danzpunkten behandlar vidare dina personuppgifter för att ge bättre och personanpassade
                    erbjudanden och tjänster. Danzpunkten behandlar också dina personuppgifter för att minska
                    risken att vi skickar irrelevant marknadsföring till dig. Personuppgifter kan till exempel komma att
                    behandlas, länkas, segmenteras och analyseras för att, genom riktad marknadsföring via t e x
                    e-mail, push-notifikationer i appen, och reklam i tredjepartskanaler, tillhandahålla information,
                    erbjudanden och rekommendationer kring Danzpunktens eller våra partners produkter och
                    tjänster, vilka är anpassade efter dina preferenser, beteende, behov eller livsstil. De kan också
                    användas som grund för riktad marknadsföring till nya potentiella användare som liknar
                    Danzpunktens redan existerande användare. Detta kan t e x inkludera behandling av
                    personuppgifter med syfte att skapa anpassad marknadsföring riktad mot sådana nya
                    potentiella användare på tredjepartsplattformar så som Facebook och Google. Danzpunkten
                    kan också komma att analysera och kombinera information om dig som Danzpunkten har
                    tillgång till via appen från dina onlinebesök eller andra källor (t e x Facebook eller andra
                    liknande leverantörer av sociala medier) för de syften som anges i detta avsnitt.
                </TextParagraph>
                <TextParagraphHeading>6.3.</TextParagraphHeading>
                <TextParagraph>
                    Därutöver behandlar Danzpunkten dina personuppgifter för att kunna förhindra, upptäcka och
                    utreda potentiella förbjudna eller olagliga aktiviteter, inkluderat bedrägeri, och för att upprätthålla
                    våra användarvillkor.
                </TextParagraph>
                <UnderHeading>
                    7. VAD ÄR DEN RÄTTSLIGA GRUNDEN FÖR BEHANDLINGEN?
                </UnderHeading>
                <TextParagraphHeading>7.1.</TextParagraphHeading>
                <TextParagraph>
                    Majoriteten av de personuppgifter som Danzpunkten behandlar om dig behandlas för att
                    Danzpunkten ska kunna tillhandahålla appen till dig, t e x administrera den och
                    säkerhetsställa/utveckla dess funktioner.
                </TextParagraph>
                <TextParagraphHeading>7.2.</TextParagraphHeading>
                <TextParagraph>
                    En del av personuppgifterna behandlas med stöd av en intresseavvägning, d v s att
                    Danzpunktens berättigade intresse av att behandla uppgifterna väger tyngre än den risk för din
                    integritet som behandlingen kan innebära. Så är fallet när Danzpunkten behandlar dina
                    personuppgifter för supportsyften.
                </TextParagraph>
                <TextParagraphHeading>7.3.</TextParagraphHeading>
                <TextParagraph>
                    Utöver detta behandlas vissa uppgifter med stöd av ditt samtycke. Så är fallet vid behandling av
                    sådana personuppgifter som Danzpunkten får tillgång till via ditt Facebook-konto för direkt
                    marknadsföring av Danzpunktens eller Danzpunktens samarbetspartners varor och tjänster. Om
                    du tillhandahåller personuppgifter om andra fysiska personer, såsom dina familjemedlemmar, är
                    du ansvarig för att säkerställa att de samtycker till behandling av sina personuppgifter.
                </TextParagraph>
                <TextParagraphHeading>7.4.</TextParagraphHeading>
                <TextParagraph>
                    I den mån behandling av personuppgifter sker med stöd av samtycke som enda rättsliga grund,
                    är det frivilligt för dig att lämna ett sådant samtycke och du kan när som helst, helt eller delvis,
                    återkalla ditt samtycke.
                </TextParagraph>
                <UnderHeading>8. DATASÄKERHET OCH INTEGRITET</UnderHeading>
                <TextParagraphHeading>8.1.</TextParagraphHeading>
                <TextParagraph>
                    Säkerheten, integriteten och sekretessen för dina personuppgifter är väldigt viktig för oss. Vi har
                    vidtagit tekniska, administrativa och fysiska säkerhetsåtgärder som är utformade för att skydda
                    dina personuppgifter från i synnerhet obehörig tillgång eller röjande, användning, förändring,
                    förstöring och förlust. Från tid till annan utvärderar vi dessa åtgärder för att utreda behovet av
                    att vidta ytterligare åtgärder eller uppdatera existerande rutiner. Vänligen notera att det, trots att
                    vi vidtagit dessa åtgärder, finns väldigt få säkerhetsåtgärder som är ogenomträngliga. Vi är
                    därför tacksamma om du informerar oss omedelbart ifall du upptäcker misstänkta aktiviteter i
                    appen eller andra tjänster som Danzpunkten tillhandahåller.
                </TextParagraph>
                <UnderHeading>9. VEM LÄMNAR DANZPUNKTEN UT UPPGIFTERNA TILL?</UnderHeading>
                <TextParagraphHeading>9.1.</TextParagraphHeading>
                <TextParagraph>
                    Danzpunkten kan komma att lämna ut dina personuppgifter till samarbetspartners, leverantörer
                    och andra tredje parter i syfte att fullgöra avtal med dig, leverantörer eller samarbetspartners.
                    Personuppgifter kan även i övrigt komma att lämnas ut i enlighet med de syften som anges i
                    denna integritetspolicy.
                </TextParagraph>
                <TextParagraphHeading>9.2.</TextParagraphHeading>
                <TextParagraph>
                    Danzpunkten kan även komma att lämna ut dina personuppgifter till tredje parter i syfte att
                    skicka anpassad reklam och marknadsföring till dig, för att undvika att skicka irrelevanta utskick
                    till dig, för att mäta framgången och räckvidden av vår digitala marknadsföring och för andra
                    säljfrämjande ändamål. Så förhåller det sig när Danzpunkten använder remarketing-tekniker
                    och information om trafik på webbplatser, intressen och demografi som rapporteras i Google
                    Analytics, anpassade uppladdningar till t ex Facebook och Google och när vi informerar våra
                    samarbetspartners, såsom Facebook och Google, om att en viss marknadsföringsåtgärd
                    genererade en ny specifik kund eller ett antal nya kunder.
                </TextParagraph>
                <TextParagraphHeading>9.3.</TextParagraphHeading>
                <TextParagraph>
                    Danzpunkten kan även komma att lämna ut personuppgifter till bolag som behandlar
                    personuppgifter för Danzpunktens räkning, exempelvis IT-leverantörer och kundtjänstföretag.
                    Om personuppgifter lämnas ut till företag som behandlar personuppgifter för Danzpunktens
                    räkning ingår Danzpunkten biträdesavtal med sådana parter för att säkerställa att en hög
                    skyddsnivå för dina personuppgifter upprätthålls.
                </TextParagraph>
                <TextParagraphHeading>9.4.</TextParagraphHeading>
                <TextParagraph>
                    Personuppgifter kan också komma att lämnas ut om det är nödvändigt för att följa gällande lag
                    eller krav från myndigheter, för att tillvarata Danzpunktens rättsliga intressen eller för att
                    upptäcka, förebygga eller uppmärksamma bedrägerier och andra säkerhetsrelaterade eller
                    tekniska problem.
                </TextParagraph>
                <TextParagraphHeading>9.5.</TextParagraphHeading>
                <TextParagraph>
                    I egenskap av en internationell affärsverksamhet, kan vi lagra dina personuppgifter inom den
                    Europeiska Unionen (EU), inom det Europeiska Ekonomiska Samarbetsområdet (EES) eller i
                    andra länder. När dina personuppgifter överförs mellan olika länder, sker överföringen till de
                    tidigare beskrivna kategorierna av mottagare och för de syften som nämns i denna
                    integritetspolicy. Om dina personuppgifter överförs och lagras i land utanför EU/EES, vidtas
                    särskilda säkerhetsåtgärder, vi kommer t ex säkerställa att sådan överföring sker i enlighet med
                    denna integritetspolicy och i enlighet med tillämpliga lagar, till exempel genom att teckna
                    dataöverföringsavtal som inkluderar de standardiserade modellklausuler för dataöverföring som
                    antagits av EU-kommissionen.
                </TextParagraph>
                <UnderHeading>10. LÄNKAR TILL EXTERNA WEBBPLATSER</UnderHeading>
                <TextParagraphHeading>10.1.</TextParagraphHeading>
                <TextParagraph>
                    Information som tillhandahålls av Danzpunkten kan innehålla länkar till webbplatser som drivs
                    eller ägs av någon annan än Danzpunkten. Danzpunkten ansvarar inte för behandlingen av
                    personuppgifter som sker på dessa webbplatser. Danzpunkten uppmuntrar dig att ta del av
                    information om behandling av personuppgifter för den aktör som ansvarar för den aktuella
                    webbsidan eller app.
                </TextParagraph>
                <UnderHeading>
                    11. ÄNDRINGAR I INTEGRITETSPOLICYN
                </UnderHeading>
                <TextParagraphHeading>
                    11.1.
                </TextParagraphHeading>
                <TextParagraph>
                    Denna Integritetspolicy kan från tid till annan komma att justeras. Om Danzpunkten genomförväsentliga förändringar i integritetspolicyn kommer Danzpunkten att informera dig via e-posteller genom appen innan förändringarna träder i kraft. Den senaste versionen av vårintegritetspolicy kommer också att publiceras på Danzpunktens hemsida. Ifall någon ändringkräver ditt samtycke, kommer Danzpunkten be om ett sådant nytt samtycke från dig. Vi uppmuntrar dig att regelbundet läsa denna integritetspolicy för att hålla dig informerad om hur vibehandlar personuppgifter.
                </TextParagraph>
                <UnderHeading>
                    12. DINA RÄTTIGHTETER ENLIGT EU/EES LAG
                </UnderHeading>
                <TextParagraphHeading>
                    12.1.
                </TextParagraphHeading>
                <TextParagraph>
                    Om du befinner dig inom EU/EES har du följande rättigheter (utöver rätten att återkalla ditt
                    samtycke);
                    <br />
                    <br />
                    Rätt till tillgång: Du har rätt att fråga oss ifall vi behandlar personuppgifter om dig och, om så är
                    fallet, begära information om vilka personuppgifter vi har om dig samt varför och hur vi
                    behandlar dem.
                    <br /><br />
                    Rätt till rättelse: Vi är skyldiga att på din begäran rätta eller komplettera felaktiga respektive
                    ofullständiga personuppgifter.<br /><br />
                    Rätt till radering (rätten att bli glömd): I vissa situationer är vi skyldiga att på din begäran radera
                    dina personuppgifter.<br /><br />
                    Rätt till behandlingsbegränsning: I vissa situationer är vi skyldiga att på din begäran begränsa
                    vår användning av dina personuppgifter. I sådana situationer får vi endast använda dina
                    personuppgifter för vissa begränsade syften som framgår av lag.<br /><br />
                    Rätt till dataportabilitet: Under vissa omständigheter kan du ha rätt att få ta del av dina
                    personuppgifter som vi behandlar i ett strukturerat, allmänt använt och maskinläsbart format
                    samt ha rätt att överföra sådana personuppgifter till en annan enhet.<br /><br />
                    Rätt att invända: I vissa situationer kan du ha rätt att invända mot vår användning av dina
                    personuppgifter. Vi kan då komma att bli skyldiga att upphöra med användningen av
                    personuppgifterna. Ett exempel på när detta gäller är när personuppgifter används för
                    marknadsföring och profilering.
                </TextParagraph>
                <TextParagraphHeading>
                    12.2.

                </TextParagraphHeading>
                <TextParagraph>
                    Vänligen notera att vissa av rättigheterna ovan enbart gäller i särskilda situationer, t ex. rätten till
                    dataportabilitet som enbart gäller för det fall behandlingen grundar sig på ett avtal eller
                    samtycke och behandlingen sker automatiserat och rätten till radering, som endast gäller när
                    personuppgifter behandlas med stöd av en intresseavvägning eller samtycke. För de fall du har
                    frågor om hur Danzpunkten behandlar dina personuppgifter, vill utöva någon av dina rättigheter
                    eller vill veta mer om rättigheterna, vänligen kontakta oss via info@danzpunkten.se
                </TextParagraph>
                <TextParagraphHeading>
                    12.3.
                </TextParagraphHeading>
                <TextParagraph>
                    Om du inte vill att Danzpunkten använder dina personuppgifter för direktmarknadsföring kan du
                    meddela detta skriftligen till Danzpunkten. Vad gäller push-notifikationer kan du (i de flesta
                    mobilenheter och surfplattor) välja att inte motta sådana notifikationer genom att gå in under "Inställningar" på din enhet och klicka på "Notiser" och sedan ändra dessa inställningar för vissa
                    eller samtliga appar på din enhet.
                </TextParagraph>
                <TextParagraphHeading>
                    12.4.
                </TextParagraphHeading>
                <TextParagraph>
                    Du har även rätt att när som helst inge klagomål till en tillsynsmyndighet om du anser att dina
                    personuppgifter behandlas i strid med tillämplig dataskyddslagstiftning. Om du är bosatt i, din
                    arbetsplats ligger i eller ifall den påstått lagstridiga behandlingen har skett i Sverige, kan du inge
                    ditt klagomål till Datainspektionen.
                </TextParagraph>
                <BottomPadding />
            </Container>
        </Wrapper>
    )
}
export default PrivacyPolicy;
