import React, { useState, useEffect } from "react";
import styled from "styled-components";
import exit from "../../../assets/exit.png";
import { urlUserAdd } from "../../../helpers/url";
import axios from "axios";
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 5;
    @media (max-width: 767px) {
        margin-top: 40px;
    }
`;
const Header = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 28.8px;
    align-items: center;
    @media (max-width: 767px) {
        padding: 18.63px;
    }
`;
const TextHeading = styled.h2`
    padding: 0px;
    margin: 28.8px 0px 0px;
    font-weight: 700;
    font-size: 25.3125px;
    color: #f3f3f8;
    @media (max-width: 767px) {
        margin: 18.63px 0px 0px;
    }
`;
const Exit = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35.443125px;
    height: 35.443125px;
    cursor: pointer;
    position: absolute;
    z-index: 6;
    top: 14.4px;
    right: 14.4px;
    border-radius: 10px;
    border: solid 2px rgba(237, 236, 242, 0.5);
    @media (max-width: 767px) {
        display: none;
    }
`;
const Input = styled.input`
    outline: none;
    width: 100%;
    height: 43.678125px;
    border-radius: 21.6px;
    font-family: "Nunito";
    font-weight: 400;
    color: #f3f3f8;
    background-color: #4d4b83;
    text-indent: 15px;
    margin-bottom: 14.4px;
    font-size: 12.65625px;
    border: ${(props) =>
        props.hasError == "error"
            ? `solid 1.5px #F47060`
            : `solid 1.5px #4d4b83`};
    ::placeholder {
        color: rgba(243, 243, 248, 0.5);
    }
`;
const InputChoiceContainer = styled.div`
    width: 100%;
    height: 43.678125px;
    border-radius: 21.6px;
    font-family: "Nunito";
    font-weight: 400;
    color: #f3f3f8;
    background-color: #4d4b83;
    text-indent: 15px;
    margin-bottom: 14.4px;
    font-size: 12.65625px;
    line-height: 43.678125px;
    border: ${(props) =>
        props.hasError == "error"
            ? `solid 1.5px #F47060`
            : `solid 1.5px #4d4b83`};
    position: relative;
    @media (max-width: 767px) {
        margin-bottom: 9.315px;
    }
`;
const InputChoiceWrapper = styled.div`
    box-sizing: content-box;
    padding: 1.5px;
    width: 100%;
    position: absolute;
    top: -1.5px;
    left: -1.5px;
    height: auto;
    background-color: #4d4b83;
    border: solid 1.5px #4d4b83;
    box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    border-radius: 21.6px;
    font-family: "Nunito";
    font-weight: 400;
    color: rgba(243, 243, 248, 0.5);
    text-indent: 15px;
    margin-bottom: 14.4px;
    font-size: 12.65625px;
    line-height: 43.678125px;
    @media (max-width: 767px) {
        margin-bottom: 9.315px;
    }
`;
const InputChoices = styled.div`
    width: 100%;
`;
const InputChoice = styled.div`
    width: 100%;
    height: 43.678125px;
    font-family: "Nunito";
    font-weight: 400;
    color: ${(props) =>
        props.hasRole == "true" ? `#F3F3F8` : `rgba(243,243,248, 0.5)`};
    cursor: pointer;
    &:hover {
        color: #f3f3f8;
    }
`;
const ChoiceContainer = styled.div`
    color: ${(props) =>
        props.hasRole == "true" ? `#F3F3F8` : `rgba(243,243,248, 0.5)`};
`;
const TextParagraph = styled.p`
    width: 100%;
    font-size: 13.921875px;
    font-family: Nunito;
    font-weight: 400;
    color: rgba(243, 243, 248, 0.5);
    text-align: center;
`;
const EmailSpan = styled.span`
    color: rgba(243, 243, 248, 0.5);
`;
const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 43.2px;
    margin-top: 18.4px;
    width: 154.36125px;
    height: 43.2px;
    font-size: 13.921875px;
    font-family: Nunito;
    font-weight: 400;
    color: #f3f3f8;
    background-color: #9d9cbd;
    border-radius: 21.6px;
    cursor: pointer;
    @media (max-width: 500px) {
        margin-top: 65px;
        
    }
`;
const Image = styled.img`
    width: 14.23125px;
    height: 14.23125px;
`;
const InfoContainer = styled.div`
    font-size: 11.390625px;
    margin-top: 26.8px;
    color: #f3f3f8;
    font-family: "Lato";
    font-weight: 300;
    @media (max-width: 500px) {
        margin-top: 18.63px;
    }
`;
const LoginButton = styled.div`
    color: rgba(237, 236, 242, 0.5);
    font-family: "Nunito";
    font-size: 13.921875px;
    margin-top: 7.2px;
    cursor: pointer;
`;
const SignUp = ({
    setSignUpHeight,
    exitHandler,
    loginHandler,
    signupHandlerExit,
    userAlreadyExists,
}) => {
    const [inputs, setInputs] = useState({
        email: { value: "", error: "" },
        firstName: { value: "", error: "" },
        lastName: { value: "", error: "" },
        birthDate: { value: "", error: "" },
        role: { value: "", error: "" },
        dirtyFields: [],
    });
    const [roleChoice, setRoleChoice] = useState(false);
    const [statusCode, setStatusCode] = useState();
    const [hasRole, setHasRole] = useState("false");
    const [hasDirtyFields, setHasDirtyFields] = useState(false);
    const signupHandler = async (e) => {
        e.preventDefault();
        let hasError = false;
        if (inputs.birthDate.error == "no data"){
            hasError = true;
        }
        if (hasError){
            
        }else{
            try {
            
                const response = await axios.post(urlUserAdd, {
                    email: inputs.email.value.toLowerCase(),
                    firstName: inputs.firstName.value.toLowerCase(),
                    lastName: inputs.lastName.value.toLowerCase(),
                    birthDate: inputs.birthDate.value.toLowerCase(),
                    role: inputs.role.value.toLowerCase(),
                });
                if (response.status == 204) {
                    for (const input in inputs) {
                        if (inputs[input].value.length <= 0)
                            inputs[input].error = "no data";
                        
                        if (input == "dirtyFields") {
                            inputs[input] = [];
                        }
                    }
            
                    setStatusCode(response.status);
                    setHasDirtyFields(false);
                }
                if (response.status == 203) {
                    userAlreadyExists(inputs.email.value);
                }
                if (response.status == 202) {
                    for (const input in inputs) {
                        if (input == "email") {
                            inputs[input].error = "no data";
                        }
                        if (input == "dirtyFields") {
                            inputs[input] = [];
                        }
                    }
                    setStatusCode(response.status);
                    setHasDirtyFields(false);
                    // add an statement for different messages like "email" and "user". "email" for not matching an email or "user" for user already exists.
                }
                if (response.status == 201) {
                    setStatusCode(response.status);
                    setHasDirtyFields(false);
                    for (const input in inputs) {
                        if (input == "dirtyFields") {
                            inputs[input] = [];
                        }
                    }
                }
            } catch (error) {
                console.log("response", error.response);
            }
        }
    };
    useEffect(() => {
        if (statusCode == 201) {
            setSignUpHeight("215");
            setTimeout(() => {
                setHasRole("false");
                setInputs({
                    email: { value: "", error: "" },
                    firstName: { value: "", error: "" },
                    lastName: { value: "", error: "" },
                    birthDate: { value: "", error: "" },
                    role: { value: "", error: "" },
                    dirtyFields: [],
                });
                signupHandlerExit();
            }, 5000);
        }
        if (inputs.dirtyFields.length > 1) {
            setHasDirtyFields(true);
        }
    }, [statusCode, roleChoice, hasRole, inputs.dirtyFields]);
    const errorHandler = (err) => {
        if (err == "no data") return "error";
    };
    const handleKeydown = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        let msg;
        if (event.keyCode === 8) {
            msg = {
                error: "",
                value: value.slice(0, -1),
            };
            setInputs((values) => ({
                ...values,
                [name]: msg,
                dirtyFields: [...values.dirtyFields, name],
            }));
        }
        if (event.key === "Enter"){
            event.target.blur();
        }
    };
    const validate = (value) => {
        if (
            value.charAt(0) == 1 ||
            (value.charAt(0) == 2 && value.charAt(1) == 0) ||
            value.charAt(1) == 9
        ) {
            if (value.charAt(4) == 0 || value.charAt(4) == 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        let msg;
        if (name == "birthDate") {
            let date = value.replace(/[^\d]/g, "");
            let str;

            if (value.length >= 10) {
                if (date) {
                    let val = validate(date)
                    console.log(validate, val);
                    if (validate(date)) {
                        msg = {
                            error: "",
                            value: value,
                        };
                    } else {
                        msg = {
                            error: "no data",
                            value: value,
                        };
                        console.log('msg', msg);
                    }
                } else {
                    msg = {
                        error: "no data",
                        value: "",
                    };
                }
            } else {
                if (date.length > 4 && date.length < 6) {
                    str = date.slice(0, 4) + "-" + date.slice(4);
                    console.log("slice 4", str.slice(5));
                }
                if (date.length > 6) {
                    str =
                        date.slice(0, 4) +
                        "-" +
                        date.slice(4, 6) +
                        "-" +
                        date.slice(6, 8);
                }
                if (date) {
                    msg = {
                        error: "",
                        value: str,
                    };
                } else {
                    msg = {
                        error: "no data",
                        value: "",
                    };
                }
            }
        } else {
            msg = {
                error: "",
                value: value,
            };
        }
        setInputs((values) => ({
            ...values,
            [name]: msg,
            dirtyFields: [...values.dirtyFields, name],
        }));
    };
    const roleChoiceHandler = (value) => {
        const name = "role";
        let msg = {
            error: "",
            value: value,
        };
        setHasRole("true");
        setInputs((values) => ({
            ...values,
            [name]: msg,
        }));
        setRoleChoice(false);
    };
    const inputChoicesHandler = (e) => {
        e.preventDefault();
        setRoleChoice(true);
    };
    return (
        <Wrapper>
            <Header>
                <Exit onClick={exitHandler}>
                    <Image src={exit} />
                </Exit>
                {statusCode == 201 ? (
                    <TextHeading>Konto skapas</TextHeading>
                ) : (
                    <TextHeading>Skapa konto</TextHeading>
                )}
            </Header>
            <Content>
                {statusCode == 201 ? (
                    <TextParagraph>
                        Ett email har skickats till{" "}
                        <EmailSpan>{inputs.email.value}</EmailSpan>, klicka på
                        länken i mejlet för att fortsätta registeringen.
                    </TextParagraph>
                ) : (
                    <>
                        <Input
                            type="text"
                            hasError={errorHandler(inputs.email.error)}
                            placeholder="E-post"
                            name="email"
                            onChange={handleChange}
                            value={inputs.email.value}
                            autoComplete="off"
                        />
                        <Input
                            type="text"
                            hasError={errorHandler(inputs.firstName.error)}
                            placeholder="Förnamn"
                            name="firstName"
                            onChange={handleChange}
                            value={inputs.firstName.value}
                            autoComplete="off"
                        />
                        <Input
                            type="text"
                            hasError={errorHandler(inputs.lastName.error)}
                            placeholder="Efternamn"
                            name="lastName"
                            onChange={handleChange}
                            value={inputs.lastName.value}
                            autoComplete="off"
                        />
                        <Input
                            type="text"
                            hasError={errorHandler(inputs.birthDate.error)}
                            placeholder="Födelsedag"
                            name="birthDate"
                            onChange={handleChange}
                            onKeyDown={handleKeydown}
                            value={inputs.birthDate.value}
                            maxLength="10"
                            autoComplete="off"
                        />
                        <InputChoiceContainer
                            hasError={errorHandler(inputs.role.error)}
                        >
                            <ChoiceContainer
                                onClick={(e) => inputChoicesHandler(e)}
                                hasRole={hasRole}
                            >
                                {inputs.role.value.length <= 0
                                    ? "Dansroll"
                                    : inputs.role.value}
                            </ChoiceContainer>
                            {roleChoice ? (
                                <InputChoiceWrapper>
                                    <InputChoices>
                                        <InputChoice
                                            hasRole={
                                                inputs.role.value == "Förare"
                                                    ? "true"
                                                    : "false"
                                            }
                                            onClick={(e) =>
                                                roleChoiceHandler("Förare")
                                            }
                                        >
                                            Förare
                                        </InputChoice>
                                        <InputChoice
                                            hasRole={
                                                inputs.role.value == "Följare"
                                                    ? "true"
                                                    : "false"
                                            }
                                            onClick={(e) =>
                                                roleChoiceHandler("Följare")
                                            }
                                        >
                                            Följare
                                        </InputChoice>
                                        <InputChoice
                                            hasRole={
                                                inputs.role.value == "Båda"
                                                    ? "true"
                                                    : "false"
                                            }
                                            onClick={(e) =>
                                                roleChoiceHandler("Båda")
                                            }
                                        >
                                            Båda
                                        </InputChoice>
                                    </InputChoices>
                                </InputChoiceWrapper>
                            ) : null}
                        </InputChoiceContainer>

                        <Button
                            onClick={
                                hasDirtyFields
                                    ? signupHandler
                                    : (e) => console.log("hasDirtyFields")
                            }
                            
                        >
                            Registrera
                        </Button>
                        <InfoContainer>
                            Har du redan ett konto på Danzpunkten?
                        </InfoContainer>
                        <LoginButton onClick={loginHandler}>
                            Klicka här
                        </LoginButton>
                    </>
                )}
            </Content>
        </Wrapper>
    );
};
export default SignUp;
