import React, { useEffect, useState } from "react";
import styled from "styled-components";

import events from "../../../assets/events.png";
import search from "../../../assets/search.png";
import useScrollPosition from "../../../hooks/useScrollPosition";
const Wrapper = styled.div`
    width: 100%;
    height: 2000px;
    display: flex;
    @media (max-width: 767px) {
        padding-left: 18.63px;
        padding-right: 18.63px;
        height: auto;
    }
    @media (min-width: 901px) and (max-width: 1195px){
        padding-left: 28.8px;
        padding-right: 28.8px;
    }
    @media (min-width: 768px) and (max-width: 900px){
        padding-left: 28.8px;
        padding-right: 28.8px;
        height: auto;
    }
`;
//
// top: 120.2px;
const ContentContainer = styled.div`
    display: flex;
    width: 100%;
`;

const Content = styled.div`
    display: flex;
    width: 48%;
    height: 916.4375px;
    align-items: center;
    position: sticky;
    top: 2px;
`;
const ImageContainer = styled.div`
    display: flex;
    margin-left: 129.375px;
    width: 249.446875px;
    height: 540.0625px;
    align-items: center;
`;
const Image = styled.img`
    width: 100%;
`;
const TextContainer = styled.div`
    width: 48%;
    height: auto;
    margin-left: 4%;
  
`;
const TopWrapper = styled.div`
    width: 100%;
    height: 50%;
`;
const BottomWrapper = styled.div`
    width: 100%;
    height: 50%;
`;
const TopColumn = styled.div`
    margin-top: ${(props) => props.marginTop};
    position: sticky;
    top: 370px;
    height: min-content;
    width: 100%;
`;
const BottomColumn = styled.div`
    margin-top: ${(props) => props.marginTop};

    height: min-content;
    width: 100%;
`;
const TextHeading = styled.h2`
    padding: 0px 0px 14.4px 0px;
    margin: 0px;
    color: #66659c;
    font-size: 33.75px;
    font-weight: 700;
    text-align: left;
    @media (max-width: 767px) {
        margin-top: 160px;
        font-size: 25.62px;
        text-align: left;
        padding: 0px 0px 9.3px 0px; 
    }
    @media (min-width: 768px) and (max-width: 900px){
        margin-top: 160px;
        text-align: left;
    }
`;
const TextUnderHeading = styled.h3`
    margin: ${(props) => props.margin};
    padding: 0px;
    font-weight: 400;
    font-size: 19.805625px;
    color: #66659c;
    text-align: left;
    @media (max-width: 767px) {
        font-size: 16.86562px;
        text-align: left;
    }
    @media (min-width: 768px) and (max-width: 900px){
        text-align: left;
    }
`;

const TextParagraph = styled.p`
    margin: 0px 0px 0px 0px;
    padding: 0px;
    color: #4d4d4d;
    font-size: ${(props) => props.fontsize};
    font-weight: 400;
`;
const MobileTextParagraph = styled.p`
    margin: 0px 0px 0px 0px;
    padding: 0px;
    color: #4d4d4d;
    font-size: 19.805625px;
    font-weight: 400;
    text-align: left;
    @media (max-width: 767px) {
        font-size: 16.86562px;
    }
`;
const MobileUnderTextParagraph = styled.p`
    margin: 0px 0px 0px 0px;
    padding: 0px;
    color: #4d4d4d;
    font-size: 17.510625px;
    font-weight: 400;
    text-align: left;
    @media (max-width: 767px) {
        font-size: 14.510625px;
    }
`;
const MobileImageContainer = styled.div`
    margin-top: 18.63px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @media (min-width: 767px) and (max-width: 1195px){
        margin-top: 28.8px;

    }
`;
const MobileImage = styled.img`
    width: 200.6616666666667px;
`;
const MobileContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const Application = ({ scrollToApp }) => {
    const [position, setPosition] = useState(false);
    const scrollPosition = useScrollPosition();
    const [mobile, setMobile] = useState(false);
    useEffect(() => {
        if (scrollPosition >= 2500) {
            setPosition(true);
        } else {
            setPosition(false);
        }
    }, [scrollPosition]);
    useEffect(() => {
        let w = window.innerWidth;
        if (w < 900) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [mobile]);
    return (
        <Wrapper ref={scrollToApp}>
            {mobile ? (
                <MobileContainer>
                    <TextHeading>Dansbibloteket</TextHeading>
                    <MobileTextParagraph>
                        Åtnjut hela Danzpunktens samlade register av danser.
                    </MobileTextParagraph>
                    <MobileUnderTextParagraph>
                        Danserna utgår från dagens datum efter dansställets
                        namn. Scrolla neråt för att ladda fler danser.
                    </MobileUnderTextParagraph>
                    <MobileImageContainer>
                        <MobileImage src={events} />
                    </MobileImageContainer>
                    <TextHeading>Sortera Danser</TextHeading>
                    <MobileTextParagraph fontsize={"19.805625px"}>
                        Hitta danser som intresserar dig.
                    </MobileTextParagraph>
                    <MobileUnderTextParagraph>
                        Med sorteringverktyget kan du kombinera flera
                        <br />
                        sökalternativ samtidigt.
                    </MobileUnderTextParagraph>
                    <TextUnderHeading margin={"14.4px 0px 0px 0px"}>
                        Sök dansband
                    </TextUnderHeading>
                    <MobileUnderTextParagraph>
                        Skriv dansbandet du önskar utforska.
                    </MobileUnderTextParagraph>
                    <TextUnderHeading margin={"14.4px 0px 0px 0px"}>
                        Avstånd
                    </TextUnderHeading>
                    <MobileUnderTextParagraph>
                        Bestäm max avståndet från din nuvarade position till
                        dansen.
                    </MobileUnderTextParagraph>
                    <TextUnderHeading margin={"14.4px 0px 0px 0px"}>
                        Veckodagar
                    </TextUnderHeading>
                    <MobileUnderTextParagraph>
                        Välj alla eller enskilda veckodagar.
                    </MobileUnderTextParagraph>
                    <MobileImageContainer>
                        <MobileImage src={events} />
                    </MobileImageContainer>
                </MobileContainer>
            ) : (
                <>
                <ContentContainer>
                    <Content>
                        <ImageContainer>
                            {position ? (
                                <Image src={search} />
                            ) : (
                                <Image src={events} />
                            )}
                        </ImageContainer>
                    </Content>
                    <TextContainer>
                        <TopWrapper>
                            <TopColumn marginTop={"170px"}>
                                <TextHeading>Dansbibloteket</TextHeading>
                                <TextParagraph fontsize={"19.805625px"}>
                                    Åtnjut hela Danzpunktens samlade register av
                                    danser.
                                </TextParagraph>
                                <TextParagraph fontsize={"17.510625px"}>
                                    Danserna utgår från dagens datum efter
                                    dansställets namn. Scrolla neråt för att
                                    ladda fler danser.
                                </TextParagraph>
                            </TopColumn>
                        </TopWrapper>
                        <BottomWrapper>
                            <BottomColumn marginTop={"170px"}>
                                <TextHeading>Sortera Danser</TextHeading>
                                <TextParagraph fontsize={"19.805625px"}>
                                    Hitta danser som intresserar dig.
                                </TextParagraph>
                                <TextParagraph fontsize={"17.510625px"}>
                                    Med sorteringverktyget kan du kombinera
                                    flera
                                    <br />
                                    sökalternativ samtidigt.
                                </TextParagraph>
                                <TextUnderHeading margin={"14.4px 0px 0px 0px"}>
                                    Sök dansband
                                </TextUnderHeading>
                                <TextParagraph fontsize={"17.510625px"}>
                                    Skriv dansbandet du önskar utforska.
                                </TextParagraph>
                                <TextUnderHeading margin={"14.4px 0px 0px 0px"}>
                                    Avstånd
                                </TextUnderHeading>
                                <TextParagraph fontsize={"17.510625px"}>
                                    Bestäm max avståndet från din nuvarade
                                    position till dansen.
                                </TextParagraph>
                                <TextUnderHeading margin={"14.4px 0px 0px 0px"}>
                                    Veckodagar
                                </TextUnderHeading>
                                <TextParagraph fontsize={"17.510625px"}>
                                    Välj alla eller enskilda veckodagar.
                                </TextParagraph>
                            </BottomColumn>
                        </BottomWrapper>
                    </TextContainer>
                    </ContentContainer>
                </>
            )}
        </Wrapper>
    );
};

export default Application;
