
export const urlUserLogin = 'https://danzpunkten.se/api/user/login';
export const urlUserForgotPassword = 'https://danzpunkten.se/api/user/forgotPassword';
export const urlUserAdd = 'https://danzpunkten.se/api/user/add';
export const urlUserEdit = 'https://danzpunkten.se/api/user/edit';
export const urlUserRemove = 'https://danzpunkten.se/api/user/remove';
export const urlUserGet = 'https://danzpunkten.se/api/user/user';
export const urlUserGetPre = 'https://danzpunkten.se/api/user/preuser/';
export const urlUserToken = 'https://danzpunkten.se/api/user/refreshToken';
export const urlUserCreateToken = 'https://danzpunkten.se/api/user/createRefreshToken';
export const urlUserLogout = 'https://danzpunkten.se/api/user/logout';
export const urlUserCreatePassword = 'https://danzpunkten.se/api/user/createPassword';
export const urlEventCount = 'https://danzpunkten.se/api/event/count';
export const urlGetEvents = 'https://danzpunkten.se/api/event/getEvents';