import React, { useState } from "react";
import styled from "styled-components";

import getstarted from "../../../assets/getstarted.png";

import howto from "../../../assets/howto.png";
import howtoios from "../../../assets/howtoios.png";
const Wrapper = styled.div`
  margin-top: 176.0625px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 1004.0625px;
  @media (max-width: 767px) {
    margin-top:160px;
    height: auto;
    
  }

`;

const Image = styled.img`
  width: 126.061875px;
  height: 126.061875px;
  @media (max-width: 767px) {
    height: 91.55113888888889px;
    width:91.55113888888889px;
  }
`;

const TextHeading = styled.h2`
  padding: 0px;
  margin-top: 28.8px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #66659c;
  line-height: 33.75px;
  font-size: 33.75px;
  font-weight: 700;
  text-align: center;
  @media (max-width: 767px) {
    margin-top: 18.63px;
    line-height: 25.62px
    font-size: 25.62px;
  }
`;

const TextParagraph = styled.p`
  margin: 14.4px 0px 0px 0px;
  padding: 0px;
  color: #4d4d4d;
  font-size: 19.805625px;
  font-weight: 400;
  font-family: Nunito;
  text-align: center;
  @media (max-width: 767px) {
    margin: 9.3px 0px 0px 0px;
    padding-left:18.63px;
    padding-right:18.63px;
    font-size: 16.86562px;
  }
`;

const Container = styled.div`
  margin-top: 28.8px;
  display: flex;
  justify-content: center;
  width: 535.05px;
  @media (max-width: 767px) {
    margin-top: 18.63px;
    width:auto;
  }

`;

const GetStartedImage = styled.img`
  width: 253.125px;
  box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
  @media (max-width: 767px) {
    width: 200.6616666666667px;
  }
`;
const PaddingBottom = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    padding-bottom: 160px;
  }
`;
const SpaceBetween = styled.div`
  width: 28.8px;
  @media (max-width: 767px) {
    width: 18.63px;
  }
`;
const GetStarted = () => {
  return (
    <Wrapper>
      <Image src={getstarted} />
      <TextHeading>Kom Igång</TextHeading>
      <TextParagraph>
      Skapa ett konto genom att registrera dig här på vår hemsida och ladda sedan ner<br/> vår app från antingen Google Play eller App Store
      </TextParagraph>
      <Container>
        <a href="https://play.google.com/store/apps/details?id=se.danzpunkten" target="_blank"><GetStartedImage src={howto} /></a>
        <SpaceBetween />
        <a href="https://apps.apple.com/se/app/danzpunkten/id1635472519?l=sv" target="_blank"><GetStartedImage src={howtoios} /></a>
      </Container>
      <PaddingBottom />
    </Wrapper>
  );
};

export default GetStarted;
