import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { urlGetEvents } from "../../../helpers/url.js";
import axios from "axios";
const Wrapper = styled.div`
    width: 100%;
    height: auto;
`;
const TextContainer = styled.div`
    margin-top: 225.8px;
    display: flex;
    flex-direction: column;
    text-align: center;
    @media (max-width: 767px) {
        margin-top: 172px;
    }
`;
const TextHeading = styled.h2`
    padding: 0px;
    margin: 0px;
    color: #66659c;
    font-size: 33.75px;
    font-weight: 700;
    @media (max-width: 767px) {
        font-size: 25.62px;
    }
`;
const TextParagraph = styled.p`
    margin: 14.4px 0px 14.4px 0px;
    padding: 0px;
    color: #4d4d4d;
    font-size: 19.805625px;
    font-weight: 400;
    @media (max-width: 767px) {
        font-size: 16.86562px;
        margin-left: 0px;
        padding-left: 18.63px;
        padding-right: 18.63px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-top: 9.3px;
        margin-bottom: 9.3px;
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 490px;
    flex-wrap: wrap;
    border-radius: 15px;
    box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    margin-bottom: 10px;
    @media (max-width: 571px) {
        height: auto;
    }
    @media (min-width: 571px) and (max-width: 1070px) {
        height: 440px;
    }
`;

const Item = styled.div`
    box-sizing: border-box;
    display: flex;
    padding: 28.8px;
    &:nth-child(1) {
        padding-top: 28.8px;
        padding-bottom: 28.8px;
        padding-left: 28.8px;
        padding-right: 0px;
    }
    &:nth-child(2) {
        padding-top: 0px;
        padding-bottom: 0;
        padding-left: 28.8px;
        padding-right: 0px;
    }
    &:nth-child(3) {
        padding-top: 28.8px;
        padding-bottom: 0px;
        padding-left: 28.8px;
        padding-right: 0px;
    }
    &:nth-child(4) {
        padding-top: 28.8px;
        padding-bottom: 28.8px;
        padding-left: 28.8px;
        padding-right: 0px;
    }
    &:nth-child(5) {
        padding-top: 28.8px;
        padding-bottom: 28.8px;
        padding-left: 28.8px;
        padding-right: 0px;
    }
    &:nth-child(6) {
        padding-top: 0px;
        padding-bottom: 0;
        padding-left: 28.8px;
        padding-right: 0px;
    }
    &:nth-child(7) {
        padding-top: 28.8px;
        padding-bottom: 0px;
        padding-left: 28.8px;
        padding-right: 0px;
    }
    &:nth-child(8) {
        padding-top: 28.8px;
        padding-bottom: 28.8px;
        padding-left: 28.8px;
        padding-right: 0px;
    }
    &:nth-child(9) {
        padding-top: 28.8px;
        padding-bottom: 28.8px;
        padding-left: 28.8px;
        padding-right: 0px;
    }
    &:nth-child(10) {
        padding-top: 0px;
        padding-bottom: 0;
        padding-left: 28.8px;
        padding-right: 0px;
    }
    &:nth-child(11) {
        padding-top: 28.8px;
        padding-bottom: 0px;
        padding-left: 28.8px;
        padding-right: 0px;
    }
    &:nth-child(12) {
        padding-top: 28.8px;
        padding-bottom: 28.8px;
        padding-left: 28.8px;
        padding-right: 0px;
    }
    @media (max-width: 751px) {
        padding: 18.63px;
        &:nth-child(1) {
            padding-top: 18.63px;
            padding-bottom: 18.63px;
            padding-left: 18.63px;
            padding-right: 0px;
        }
        &:nth-child(2) {
            padding-top: 0px;
            padding-bottom: 0;
            padding-left: 18.63px;
            padding-right: 0px;
        }
        &:nth-child(3) {
            padding-top: 18.63px;
            padding-bottom: 0px;
            padding-left: 18.63px;
            padding-right: 0px;
        }
        &:nth-child(4) {
            padding-top: 18.63px;
            padding-bottom: 18.63px;
            padding-left: 18.63px;
            padding-right: 0px;
        }
        :nth-child(n + 5) {
            display: none;
        }
    }
    @media (min-width: 751px) and (max-width: 1070px) {
        padding: 18.63px;
        &:nth-child(1) {
            padding-top: 18.63px;
            padding-bottom: 18.63px;
            padding-left: 18.63px;
            padding-right: 0px;
        }
        &:nth-child(2) {
            padding-top: 0px;
            padding-bottom: 0;
            padding-left: 18.63px;
            padding-right: 0px;
        }
        &:nth-child(3) {
            padding-top: 18.63px;
            padding-bottom: 0px;
            padding-left: 18.63px;
            padding-right: 0px;
        }
        &:nth-child(4) {
            padding-top: 18.63px;
            padding-bottom: 18.63px;
            padding-left: 18.63px;
            padding-right: 0px;
        }
        &:nth-child(5) {
            padding-top: 18.63px;
            padding-bottom: 18.63px;
            padding-left: 18.63px;
            padding-right: 0px;
        }
        &:nth-child(6) {
            padding-top: 0px;
            padding-bottom: 0;
            padding-left: 18.63px;
            padding-right: 0px;
        }
        &:nth-child(7) {
            padding-top: 18.63px;
            padding-bottom: 0px;
            padding-left: 18.63px;
            padding-right: 0px;
        }
        &:nth-child(8) {
            padding-top: 18.63px;
            padding-bottom: 18.63px;
            padding-left: 18.63px;
            padding-right: 0px;
        }
        :nth-child(n + 9) {
            display: none;
        }
        @media (min-width: 871px) and (max-width: 930px) {
            padding: 18.63px;
            &:nth-child(1) {
                padding-top: 18.63px;
                padding-bottom: 18.63px;
                padding-left: 18.63px;
                padding-right: 0px;
            }
            &:nth-child(2) {
                padding-top: 0px;
                padding-bottom: 0;
                padding-left: 18.63px;
                padding-right: 0px;
            }
            &:nth-child(3) {
                padding-top: 18.63px;
                padding-bottom: 0px;
                padding-left: 18.63px;
                padding-right: 0px;
            }
            &:nth-child(4) {
                padding-top: 18.63px;
                padding-bottom: 18.63px;
                padding-left: 18.63px;
                padding-right: 0px;
            }
            &:nth-child(5) {
                padding-top: 18.63px;
                padding-bottom: 18.63px;
                padding-left: 18.63px;
                padding-right: 0px;
            }
            &:nth-child(6) {
                padding-top: 0px;
                padding-bottom: 0;
                padding-left: 18.63px;
                padding-right: 0px;
            }
            &:nth-child(7) {
                padding-top: 18.63px;
                padding-bottom: 0px;
                padding-left: 18.63px;
                padding-right: 0px;
            }
            &:nth-child(8) {
                padding-top: 18.63px;
                padding-bottom: 18.63px;
                padding-left: 18.63px;
                padding-right: 0px;
            }
            &:nth-child(9) {
                padding-top: 18.63px;
                padding-bottom: 18.63px;
                padding-left: 18.63px;
                padding-right: 0px;
            }
            &:nth-child(10) {
                padding-top: 0px;
                padding-bottom: 0;
                padding-left: 18.63px;
                padding-right: 0px;
            }
            &:nth-child(11) {
                padding-top: 18.63px;
                padding-bottom: 0px;
                padding-left: 18.63px;
                padding-right: 0px;
            }
            &:nth-child(12) {
                padding-top: 18.63px;
                padding-bottom: 18.63px;
                padding-left: 18.63px;
                padding-right: 0px;
            }
        }
    }
`;
const ItemContainer = styled.div`
    margin-left: 13.73px;
`;
const ItemDateContainer = styled.div`
    text-align: center;
`;
const ItemHeading = styled.div`
    font-size: 18.984375px;
    color: #333333;
    font-weight: 700;
`;
const ItemMusician = styled.div`
    font-size: 14.866875px;
    color: #4d4d4d;
    font-weight: 400;
    margin-top: 3.3223px;
`;
const Itemday = styled.div`
    color: #333333;
    font-weight: 300;
    font-size: 12.020625px;
    margin-top: 3.3223px;
    font-family: Lato;
    text-transform: capitalize;
`;
const Itemcity = styled.div`
    color: #333333;
    font-weight: 300;
    margin-top: 3.3223px;
    font-size: 12.020625px;
    font-family: Lato;
`;
const ItemDate = styled.div`
    font-size: 23.92875px;
    color: #333333;
    font-family: Open + Sans;
`;
const ItemDateStr = styled.div`
    font-size: 14.236875px;
    font-family: Lato;
    font-weight: 400;
    color: #66659c;
    text-transform: uppercase;
`;

    const Events = () => {
        const [events, setEvents] = useState([{
            date: '',
            time: {
                start: '',
                end: ''
            },
            musicians: [{
                name: '',
            }],
            place: {
                name: "",
                location: {
                    county: ''
                }
            }
        }]);
        const getEvents = async () => {
            try {
                const response = await axios.get(urlGetEvents);
                console.log("res", response.data.events[0].items);
                setEvents(response.data.events[0].items);
            } catch (err) {
                console.log("err", err);
            }
        };
        useEffect(() => {
            getEvents();
        }, []);
        const hideOverflow = (array) => {
            // behöver ses över.
            let count = 0;
            //.map((item, index) => { return ((index ? ', ': '') + item.name) })
            array.map(item => count += item.name.length);
            if (count >= 16) {
                if (array.length <= 2) {
                    return array.map((item, index) => { return ((index ? ', ' : '') + item.name) })
                }
                if (array.length > 2) {
                    return array[0].name + " m.fl."
                }
        
            } else {
                return array.map((item, index) => { return ((index ? ', ' : '') + item.name) })
            }
        }
        return (
            <Wrapper>
                <TextContainer>
                    <TextHeading>Danser</TextHeading>
                    <TextParagraph>
                        Ett axplock av danser, du hittar dem och många fler i appen.
                    </TextParagraph>
                </TextContainer>
                <Container>
                    {events.map((item, index) => {
                        let day;
                        let month;
                        let weekday;
                        if (item.date.length > 0) {
                            let d = new Date(item.date);

                            day = d.toLocaleString('sv-SE', { day: '2-digit' });

                            month = d.toLocaleString('sv-SE', { month: 'short' }).replace(/\./g, '');
                            if (month == "mars") {
                                month = "mar";
                            }
                            if (month == "juni") {
                                month = "jun";
                            }
                            if (month == "juli") {
                                month = "jun"
                            }
                            weekday = d.toLocaleString('sv-SE', { weekday: 'long' });
                        }

                        return (
                            <Item key={index}>
                                <ItemDateContainer>
                                    <ItemDate>{day}</ItemDate>
                                    <ItemDateStr>{month}</ItemDateStr>
                                </ItemDateContainer>
                                <ItemContainer>
                                    <ItemHeading>{item.place.name}</ItemHeading>
                                    <ItemMusician>{hideOverflow(item.musicians)}</ItemMusician>
                                    <Itemday>{weekday + " " + item.time.start + " - " + item.time.end}</Itemday>
                                    <Itemcity>{item.place.location.county}</Itemcity>
                                </ItemContainer>
                            </Item>
                        );
                    }
                    )
                    }
                </Container>
            </Wrapper>
        );
    };

    export default Events;
