import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useAuth } from "../../hooks/useAuth";
import Overlay from "../../components/UI/Overlay/Overlay";
import exit from "../../assets/exit.png";
import Home from "../../assets/Home.png";
import {
    urlUserToken,
    urlUserGet,
    urlUserEdit,
    urlUserLogout,
    urlUserForgotPassword,
    urlUserRemove,
} from "../../helpers/url.js";
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #edecf2;
`;
const WrapperContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1152px;
    width: 100%;
    margin: 0 auto;
`;
const StatusContainer = styled.div`
    position: fixed;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: 43.2px;
    background-color: #34326a;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    z-index: 2;
    @media (max-width: 767px) {
        height: 55.806px;
    }
`;
const ButtonContainer = styled.div`
    grid-column-start: ${(props) => props.start};
    grid-column-end: ${(props) => props.end};
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify};
`;
const Button = styled.div`
    font-size: 15.69375px;
    cursor: pointer;
    color: #66659c;
    margin-left: ${(props) => props.left};
    margin-right: ${(props) => props.right};
`;
const LogoContainer = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Logo = styled.img`
    width: 158.9px;
`;
const NavContainer = styled.div`
    position: fixed;
    top: 43.2px;
    min-width: 100%;
    width: 100%;
    height: 72px;
    background-color: #f3f3f8;
    z-index: 2;
    box-shadow: -1px 5px 12px -4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: -1px 7px 12px -4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -1px 5px 12px -4px rgba(0, 0, 0, 0.2);
    @media (max-width: 767px) {
        top: 55.806px;
    }
`;
const Nav = styled.div`
    width: 1152px;
    margin: 0 auto;
    height: 72px;
`;
const Menu = styled.div`
    width: 100%;
    display: flex;
    line-height: 72px;
`;

const RegContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const Heading = styled.h2`
    font-family: Nunito;
    font-weight: 700;
    color: #4d4d4d;
    text-indent: 0;
    font-size: 25.3125px;
    padding: 0px 0px 0px 28.8px;
    margin: 0px 0px 0px 0px;
    text-transform: capitalize;
`;
const Section = styled.section`
    margin-top: ${(props) => props.top};
    width: 100%;
    height: ${(props) => props.height};
    background-color: #f3f3f8;
`;
const UnderHeading = styled.h3`
    margin: 28.8px 0px 0px 28.8px;
    padding: 0px;
    font-family: Nunito;
    font-weight: 700;
    font-size: 18.984375px;
    color: #4d4d4d;
`;
const InputContainer = styled.div`
    width: 100%;
    padding-left: 14.4px;
    padding-right: 14.4px;
`;
const PasswordInput = styled.input`
    width: 100%;
    height: 43.678125px;
    border-radius: 21.8390625px;
    box-sizing: border-box;
    background-color: #f3f3f8;
    border: solid 1.5px #f3f3f8;
    padding-left: 14.4px;
    font-family: Nunito;
    font-weight: 400;
    font-size: 12.65625px;
    color: rgba(78, 78, 78, 0.5);
    &:focus {
        outline: none;
        border: solid 1.5px #f3f3f8;
        color: rgba(78, 78, 78, 0.5);
    }
`;
const Input = styled.input`
    width: 100%;
    height: 43.678125px;
    border-radius: 21.8390625px;
    box-sizing: border-box;
    background-color: #edecf2;
    border: ${(props) =>
        props.hasError == "error"
            ? `solid 1.5px #F47060`
            : `solid 1.5px #edecf2`};
    padding-left: 14.4px;
    font-family: Nunito;
    font-weight: 400;
    font-size: 12.65625px;
    color: #4e4d4d;
    &:focus {
        outline: none;
        border: solid 1.5px #9d9cbd;
        color: #4e4d4d;
    }
    text-transform: capitalize;
`;
const InputChoiceContainer = styled.div`
    text-transform: capitalize;
    width: 100%;
    height: 43.678125px;
    border-radius: 21.6px;
    font-family: "Nunito";
    font-weight: 400;
    color: #4e4d4d;
    background-color: #edecf2;
    text-indent: 15px;
    margin-bottom: 14.4px;
    font-size: 12.65625px;
    line-height: 43.678125px;
    border: ${(props) =>
        props.hasError == "error"
            ? `solid 1.5px #F47060`
            : `solid 1.5px #edecf2`};
    position: relative;
`;
const InputChoiceWrapper = styled.div`
    box-sizing: content-box;
    padding: 1.5px;
    width: 100%;
    position: absolute;
    top: -1.5px;
    left: -1.5px;
    height: auto;
    background-color: #edecf2;
    border: solid 1.5px #edecf2;
    box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    border-radius: 21.6px;
    font-family: "Nunito";
    font-weight: 400;
    color: rgba(243, 243, 248, 0.5);
    text-indent: 15px;
    margin-bottom: 14.4px;
    font-size: 12.65625px;
    line-height: 43.678125px;
`;
const InputChoices = styled.div`
    width: 100%;
`;
const InputChoice = styled.div`
    width: 100%;
    height: 43.678125px;
    font-family: "Nunito";
    font-weight: 400;
    color: ${(props) =>
        props.hasRole == "true" ? `#4e4d4d` : `rgba(78, 78, 78, 0.5)`};
    cursor: pointer;
    &:hover {
        color: #4e4d4d;
    }
`;
const ChoiceContainer = styled.div`
    color: ${(props) => (props.hasRole == "true" ? `#4e4d4d` : `#4e4d4d`)};
`;
const Label = styled.div`
    font-family: Nunito;
    font-weight: 400;
    font-size: 15.1875px;
    color: #4e4d4d;
    margin-top: ${(props) => props.top};
    margin-left: 14.4px;
    margin-bottom: 7.2px;
`;
const SaveBtn = styled.div`
    width: 151.4025px;
    height: 43.2px;
    border-radius: 21.6px;
    font-family: Nunito;
    font-weight: 400;
    font-size: 13.921875px;
    color: #f3f3f8;
    background-color: ${(props) => (props.hasChanged ? `#9d9cbd` : `#CCCBDD`)};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28.8px;
    cursor: pointer;
`;
const PaddingBottom = styled.div`
    padding-bottom: 100px;
`;
const BtnContainer = styled.div`
    display: flex;
    margin-left: 14.4px;
`;
const Btn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28.8px;
    font-family: Nunito;
    font-weight: 400;
    font-size: 15.69375px;
    cursor: pointer;
    color: ${(props) => (props.remove ? `rgba(244,112,96,1)` : `#66659c`)};
    border-radius: 21.6px;
    cursor: pointer;
    transition: 0.3s ease-out;
    &:hover {
        color: ${(props) => (props.remove ? `rgba(244,112,96,1)` : `#66659c`)};
        transition: 0.3s ease-out;
    }
`;
const Space = styled.div`
    width: 28.8px;
`;
const WrapperOverlay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 5;
`;
const HeaderOverlay = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
const TextHeadingOverlay = styled.h2`
    padding: 0px;
    margin: 28.8px 0px 0px;
    font-weight: 700;
    font-size: 25.3125px;
    color: #f3f3f8;
`;
const Exit = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35.443125px;
    height: 35.443125px;
    cursor: pointer;
    position: absolute;
    z-index: 6;
    top: 14.4px;
    right: 14.4px;
    border-radius: 10px;
    border: solid 2px rgba(237, 236, 242, 0.5);
`;
const Image = styled.img`
    width: 14.23125px;
    height: 14.23125px;
`;
const ContentOverlay = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 28.8px;
    align-items: center;
`;
const TextParagraphOverlay = styled.p`
    font-size: 13.921875px;
    font-family: Nunito;
    font-weight: 400;
    color: rgba(243, 243, 248, 0.5);
    padding: 0px;
    margin: 0px;
`;
const ButtonOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 43.2px;
    margin-top: 28.8px;
    width: 154.36125px;
    height: 43.2px;
    font-size: 13.921875px;
    font-family: Nunito;
    font-weight: 400;
    color: #f3f3f8;
    background-color: ${(props) =>
        props.remove ? `rgba(244,112,96,1)` : `#9d9cbd`};
    border-radius: 21.6px;
    cursor: pointer;
    transition: 0.3s ease-out;
`;

const EmailSpan = styled.span``;
const BarButton = styled.div`
    width: 29.475px;
    height: 29.475px;
    cursor: pointer;
    color: #66659c;
    margin-left: 18.63px;
`;
const ImageIcon = styled.img`
    width: 100%;
    height: 100%;
`;
const AccountMenu = styled.div`
    display: flex;
    z-index:12;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 55px;
    padding-left: 18.63px;
    padding-right: 18.63px;
    width: 70%;
    height: 200.3px;
    border-bottom-right-radius: 10px;
    left: 0px;
    box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    background-color: #34326a;
`;
const AccountMenuButtonContainer = styled.div`
    width: 100%;
`;
const AccountMenuButton = styled.div`
    cursor: pointer;
    width: 100%;
    height: 43.3px;
    line-height: 43.3px;
    text-align: center;
    margin-bottom: 9.315px;
    border-radius: 10px;
    background-color: ${(props) => props.color};
    color: #f3f3f8;
    font-size: 15.69375px;
`;
const Account = () => {
    const [inputs, setInputs] = useState({
        email: { value: "", error: "" },
        firstName: { value: "", error: "" },
        lastName: { value: "", error: "" },
        password: { value: "", error: "" },
        birthDate: { value: "", error: "" },
        role: { value: "", error: "" },
        dirtyFields: [],
    });
    const [token, setToken] = useState("");
    const [emailId, setEmailId] = useState("");
    const [expire, setExpire] = useState("");
    const [save, setSave] = useState(false);
    const [roleChoice, setRoleChoice] = useState(false);
    const [hasRole, setHasRole] = useState("false");
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [toggleOverlay, setToggleOverlay] = useState(false);
    const [overlayHeading, setOverlayHeading] = useState("");
    const [overlayText, setOverlayText] = useState("");
    const [isRemove, setIsRemove] = useState(false);
    const [statusCode, setStatusCode] = useState();
    const [mobile, setMobile] = useState(false);
    const [accountMenu, setAccountMenu] = useState(false);
    const navigateHandler = () => {
        navigate("/", { replace: true });
    };
    const logoutHandler = async () => {
        try {
            const response = await axios.delete(urlUserLogout);
            if (response.status === 200) {
                navigate("/", { replace: true });
                logout();
                setAccountMenu(false);
            }
        } catch (err) {
            console.log("err", err);
        }
    };
    useEffect(() => {
        refreshToken();
        getUsers();
    }, []);
    const refreshToken = async () => {
        try {
            const response = await axios.get(urlUserToken);
            console.log("refreshToken", response);
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setEmailId(decoded.emailId);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/", { replace: true });
                logout();
            }
        }
    };

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expire * 1000 < currentDate.getTime()) {
                const response = await axios.get(urlUserToken);
                config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                setToken(response.data.accessToken);
                const decoded = jwt_decode(response.data.accessToken);
                setEmailId(decoded.emailId);
                setExpire(decoded.exp);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const lengthLoop = (item) => {
        let password = "";
        for (let i = 0; i < item; i++) {
            password += "*";
        }
        return password;
    };
    const getUsers = async () => {
        const response = await axiosJWT.get(urlUserGet, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        setInputs({
            firstName: { value: response.data.user.firstName, error: "" },
            lastName: { value: response.data.user.lastName, error: "" },
            email: { value: response.data.user.email, error: "" },
            password: {
                value: lengthLoop(response.data.user.userlength),
                error: "",
            },
            birthDate: { value: response.data.user.birthDate, error: "" },
            role: {
                value:
                    response.data.user.role.charAt(0).toUpperCase() +
                    response.data.user.role.slice(1),
                error: "",
            },
            dirtyFields: [],
        });
        setSave(false);
    };
    const handleRemove = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        let msg;
        if (event.keyCode === 8) {
            msg = {
                error: "",
                value: value.slice(0, -1),
            };
            setInputs((values) => ({
                ...values,
                [name]: msg,
                dirtyFields: [...values.dirtyFields, name],
            }));
        }
    };
    const validate = (value) => {
        if (
            value.charAt(0) == 1 ||
            (value.charAt(0) == 2 && value.charAt(1) == 0) ||
            value.charAt(1) == 9
        ) {
            if (value.charAt(4) == 0 || value.charAt(4) == 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        let msg;
        if (name == "birthDate") {
            let date = value.replace(/[^\d]/g, "");
            let str;

            if (value.length >= 10) {
                if (date) {
                    let val = validate(date)
                    console.log(validate, val);
                    if (validate(date)) {
                        msg = {
                            error: "",
                            value: value,
                        };
                    } else {
                        msg = {
                            error: "no data",
                            value: value,
                        };
                        console.log('msg', msg);
                    }
                } else {
                    msg = {
                        error: "no data",
                        value: "",
                    };
                }
            } else {
                if (date.length > 4 && date.length < 6) {
                    str = date.slice(0, 4) + "-" + date.slice(4);
                    console.log("slice 4", str.slice(5));
                }
                if (date.length > 6) {
                    str =
                        date.slice(0, 4) +
                        "-" +
                        date.slice(4, 6) +
                        "-" +
                        date.slice(6, 8);
                }
                if (date) {
                    msg = {
                        error: "",
                        value: str,
                    };
                } else {
                    msg = {
                        error: "no data",
                        value: "",
                    };
                }
            }
        } else {
            msg = {
                error: "",
                value: value,
            };
        }
        setInputs((values) => ({
            ...values,
            [name]: msg,
            dirtyFields: [...values.dirtyFields, name],
        }));
    };
    const inputChoicesHandler = (e) => {
        e.preventDefault();
        setRoleChoice(true);
    };
    const roleChoiceHandler = (value) => {
        const name = "role";
        let msg = {
            error: "",
            value: value,
        };
        setHasRole("true");
        setInputs((values) => ({
            ...values,
            [name]: msg,
            dirtyFields: [...values.dirtyFields, name],
        }));
        setRoleChoice(false);
    };
    const newPasswordsHandler = async () => {
        const response = await axios.post(urlUserForgotPassword, {
            email: inputs.email.value,
            access: "new",
        });
        if (response.status == 200) {
            setStatusCode(response.status);
        }
    };
    const newPasswordsHandlerOverlay = async () => {
        setToggleOverlay(true);
        setOverlayHeading("Nytt lösenord");
        setOverlayText("Vill du skapa ett nytt lösenord?");
        setIsRemove(false);
    };
    const removeAccountHandler = async () => {
        const response = await axios.delete(urlUserRemove, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.status == 200) {
            setStatusCode(207);
        }
    };
    const removeAccountHandlerOverlay = () => {
        setToggleOverlay(true);
        setOverlayHeading("Avsluta konto");
        setOverlayText("Är du säker på att du vill ta bort ditt konto?");
        setIsRemove(true);
    };
    const exitHandler = () => {
        setToggleOverlay(false);
    };
    useEffect(() => {
        if (statusCode == 200) {
            setTimeout(() => {
                logoutHandler();
            }, 5000);
        }
        if (statusCode == 207) {
            setTimeout(() => {
                navigate("/", { replace: true });
                logout();
            }, 4500);
        }
        console.log("status code", statusCode);
    }, [statusCode]);
    const submitHandler = async () => {
        let hasError = false;
        if (inputs.birthDate.error == "no data") {
            hasError = true;
        }
        if (hasError) {

        } else {
            let errors = [];
            for (const input in inputs) {
                if (input == "dirtyFields") {
                } else {
                    if (inputs[input].value.length < 1) {
                        inputs[input].error = "no data";
                        errors.push(input);
                    } else {
                        inputs[input].error = "";
                    }
                }
            }
            if (errors.length == 0) {
                try {
                    const response = await axios.post(
                        urlUserEdit,
                        {
                            firstName: inputs.firstName.value,
                            lastName: inputs.lastName.value,
                            birthDate: inputs.birthDate.value,
                            role: inputs.role.value,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    if (response.status == 201) {
                        setSave(false);
                    }
                } catch (error) { }
            } else {
                setSave(false);
            }
        }
    };
    const errorHandler = (err) => {
        if (err == "no data") return "error";
    };
    useEffect(() => {
        if (inputs.dirtyFields.length > 0) {
            setSave(true);
        }
    }, [inputs.dirtyFields]);
    useEffect(() => {
        let w = window.innerWidth;
        if (w < 767) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [mobile]);
    return (
        <Wrapper>
            {toggleOverlay ? (
                <Overlay height="213.8" padding="18.63px">
                    <WrapperOverlay>
                        <HeaderOverlay>
                            <Exit onClick={exitHandler}>
                                <Image src={exit} />
                            </Exit>
                            <TextHeadingOverlay>
                                {overlayHeading}
                            </TextHeadingOverlay>
                        </HeaderOverlay>
                        <ContentOverlay>
                            {statusCode == 200 ? (
                                <>
                                    <TextParagraphOverlay>
                                        Ett email har skickats till{" "}
                                        <EmailSpan>
                                            {inputs.email.value}
                                        </EmailSpan>
                                        , klicka på länken i mejlet för att
                                        skapa ett nytt lösenord.
                                    </TextParagraphOverlay>
                                </>
                            ) : statusCode == 207 ? (
                                <>
                                    <TextParagraphOverlay>
                                        Ditt konto är nu avslutat och dina
                                        användaruppgifter är borttagna.
                                    </TextParagraphOverlay>
                                </>
                            ) : (
                                <>
                                    <TextParagraphOverlay>
                                        {overlayText}
                                    </TextParagraphOverlay>
                                    <ButtonOverlay
                                        remove={isRemove}
                                        onClick={
                                            isRemove
                                                ? removeAccountHandler
                                                : newPasswordsHandler
                                        }
                                    >
                                        {isRemove ? "Ta bort" : "Skapa"}
                                    </ButtonOverlay>
                                </>
                            )}
                        </ContentOverlay>
                    </WrapperOverlay>
                </Overlay>
            ) : null}
            {accountMenu ? (
                <AccountMenu>
                    <AccountMenuButtonContainer>
                        <AccountMenuButton color="#9B99BB" onClick={e => navigate('/', { replace: true })}>Hemsida</AccountMenuButton>
                        <AccountMenuButton color="#4C4B83" onClick={token == null ? "" : logoutHandler}>Logga ut</AccountMenuButton>
                    </AccountMenuButtonContainer>
                </AccountMenu>
            ) : null}
            {mobile ? (
                <StatusContainer>
                    <ButtonContainer start={1} end={2} justify="flex-start">
                        <BarButton onClick={() => setAccountMenu(prev => !prev)}>
                            <ImageIcon src={Home} />
                        </BarButton>
                    </ButtonContainer>
                    <LogoContainer>
                        <Logo src={`${logo}`} />
                    </LogoContainer>
                    <ButtonContainer
                        start={3}
                        end={4}
                        justify="flex-end"
                    ></ButtonContainer>
                </StatusContainer>
            ) : (
                <StatusContainer>
                    <ButtonContainer start={1} end={2} justify="flex-start">
                        <Button left="10vw" right="0" onClick={navigateHandler}>
                            Hemsidan
                        </Button>
                    </ButtonContainer>
                    <LogoContainer>
                        <Logo src={`${logo}`} />
                    </LogoContainer>
                    <ButtonContainer start={3} end={4} justify="flex-end">
                        <Button left="0vw" right="10vw" onClick={logoutHandler}>
                            Logga ut
                        </Button>
                    </ButtonContainer>
                </StatusContainer>
            )}
            <NavContainer>
                <Nav>
                    <Menu>
                        <Heading>
                            {inputs.firstName.value +
                                " " +
                                inputs.lastName.value}
                        </Heading>
                    </Menu>
                    <RegContainer></RegContainer>
                </Nav>
            </NavContainer>
            <WrapperContainer>
                <Section height="309.8px" top="129.6px">
                    <UnderHeading>Konto</UnderHeading>
                    <InputContainer>
                        <Label top="21.6px">E-post</Label>
                        <PasswordInput
                            type="text"
                            name="email"
                            value={inputs.email.value}
                            onChange={handleChange}
                            readOnly={true}
                        />
                        <Label top="14.4px">Lösenord</Label>
                        <PasswordInput
                            type="password"
                            name="password"
                            value={inputs.password.value}
                            onChange={handleChange}
                            readOnly={true}
                        />
                        <BtnContainer>
                            <Btn
                                remove={false}
                                onClick={newPasswordsHandlerOverlay}
                            >
                                Nytt lösenord
                            </Btn>
                            <Space />
                            <Btn
                                remove={true}
                                onClick={removeAccountHandlerOverlay}
                            >
                                Avsluta konto
                            </Btn>
                        </BtnContainer>
                    </InputContainer>
                </Section>
                <Section height="509.146875px" top="14.4px">
                    <UnderHeading>Personuppgifter</UnderHeading>
                    <InputContainer>
                        <Label top="21.6px">Förnamn</Label>
                        <Input
                            type="text"
                            name="firstName"
                            value={inputs.firstName.value}
                            onChange={handleChange}
                            hasError={errorHandler(inputs.firstName.error)}
                        />
                        <Label top="14.4px">Efternamn</Label>
                        <Input
                            type="text"
                            name="lastName"
                            value={inputs.lastName.value}
                            onChange={handleChange}
                            hasError={errorHandler(inputs.lastName.error)}
                        />
                        <Label top="14.4px">Födelsedatum</Label>
                        <Input
                            type="text"
                            hasError={errorHandler(inputs.birthDate.error)}
                            placeholder="Födelsedag"
                            name="birthDate"
                            onChange={handleChange}
                            onKeyDown={handleRemove}
                            value={inputs.birthDate.value}
                            maxLength="10"
                        />
                        <Label top="14.4px">Dansroll</Label>
                        <InputChoiceContainer
                            hasError={errorHandler(inputs.role.error)}
                        >
                            <ChoiceContainer
                                onClick={(e) => inputChoicesHandler(e)}
                                hasRole={hasRole}
                            >
                                {inputs.role.value.length <= 0
                                    ? "Dansroll"
                                    : inputs.role.value}
                            </ChoiceContainer>
                            {roleChoice ? (
                                <InputChoiceWrapper>
                                    <InputChoices>
                                        <InputChoice
                                            hasRole={
                                                inputs.role.value == "Förare"
                                                    ? "true"
                                                    : "false"
                                            }
                                            onClick={(e) =>
                                                roleChoiceHandler("Förare")
                                            }
                                        >
                                            Förare
                                        </InputChoice>
                                        <InputChoice
                                            hasRole={
                                                inputs.role.value == "Följare"
                                                    ? "true"
                                                    : "false"
                                            }
                                            onClick={(e) =>
                                                roleChoiceHandler("Följare")
                                            }
                                        >
                                            Följare
                                        </InputChoice>
                                        <InputChoice
                                            hasRole={
                                                inputs.role.value == "Båda"
                                                    ? "true"
                                                    : "false"
                                            }
                                            onClick={(e) =>
                                                roleChoiceHandler("Båda")
                                            }
                                        >
                                            Båda
                                        </InputChoice>
                                    </InputChoices>
                                </InputChoiceWrapper>
                            ) : null}
                        </InputChoiceContainer>
                        <SaveBtn
                            onClick={
                                save
                                    ? submitHandler
                                    : (e) => console.log("no save")
                            }
                            hasChanged={save}
                        >
                            Spara
                        </SaveBtn>
                    </InputContainer>
                </Section>
                {
                    //<Section height="200px" top="14.4px">
                    //<UnderHeading>Inställningar</UnderHeading>
                    //<InputContainer>
                    //  <Label top="21.6px">Gps funktion</Label>
                    // <SaveBtn>Spara</SaveBtn>
                    //</InputContainer>
                    //</Section>
                }
                <PaddingBottom />
            </WrapperContainer>
        </Wrapper>
    );
};

export default Account;
