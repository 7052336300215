import { Suspense, useEffect } from "react";
import { useLoaderData, useOutlet, Await } from "react-router-dom";
import { AuthProvider } from "../../../hooks/useAuth";


const Loading = () => {
    return <div>Loading...</div>;
};
const Alert = () => {
    return <div>Something went wrong...</div>;
};
export const AuthLayout = () => {
    const outlet = useOutlet();
    const { userPromise } = useLoaderData();
    return (
        <Suspense fallback={<Loading />}>
            <Await
                resolve={userPromise}
                errorElement={<Alert />}
                children={(user) => (
                    <AuthProvider userData={user}>{outlet}</AuthProvider>
                )}
            />
        </Suspense>
    );
};
