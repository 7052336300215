import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    @media (max-width: 767px) {
        height: auto;
        margin-top: 160px;
    }
    
`;
const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-between;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`;
const ContainerItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 561.78px;
    height: auto;
    white-space: pre-wrap;
    @media (max-width: 767px) {
        width: 100%;
    }
    @media (min-width: 767px) and (max-width: 1195px){
        width:50%;
    }
`;
const Item = styled.div`
    margin-top: 14.4px;
    width: 561.78px;
    height: auto;
    box-sizing: border-box;
    padding-left: 28.8px;
    padding-top: 14.4px;
    padding-right: 28.8px;
    padding-bottom: 14.4px;
    box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    @media (max-width: 767px) {
        width: 100%;
        padding-left: 18.63px;
        padding-top: 9.3px;
        padding-right: 18.63px;
        padding-bottom: 9.3px;
    }
    @media (min-width: 767px) and (max-width: 1195px){
        width: 100%;
        padding-left: 28.8px;
        padding-top: 14.4px;
        padding-right: 28.8px;
        padding-bottom: 14.4px;
    }
`;
const ItemHeading = styled.div`
    cursor: pointer;
    width: 100%;
    font-size: 19.805625px;
    font-weight: 400;
    color: ${(props) => (props.color == "true" ? "#66659c" : "#4d4d4d")};
    @media (max-width: 767px) {
        font-size: 16.86562px;
    }
`;
const ItemAnswer = styled.div`
    margin-top: 7.4px;
    font-size: 17.510625px;
    color: #4d4d4d;
    @media (max-width: 767px) {
        font-size: 14.510625px;
        margin-top: 4.6px;
    }
`;
const Link = styled.a`
    font-size: 17.510625px;
    text-decoration: none;
    color: #6464cc;
    @media (max-width: 767px) {
        font-size: 14.510625px;
    }
`;
const TextHeading = styled.h2`
    padding: 0px;
    margin´-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 28.8px;
    color: #66659c;
    font-size: 33.75px;
    font-weight: 700;
    @media (max-width: 767px) {
        font-size: 25.62px;
        margin-left: 18.63px;
    }
`;
const Linebreak = styled.div`
    padding-top: 7.2px;
    @media (max-width: 767px) {
        padding-top: 4.6px;
    }
`;
const Button = styled.div`
    font-size: 15.94375px;
    cursor: pointer;
    color: #66659c;
    margin-top: 18.63px;
    padding-bottom: 18.63px;
    margin-left: 18.63px;
`;
const Questions = () => {
    const [questions, setQuestion] = useState([
        {
            heading: "Vad är Danzpunkten?",
            answer: "Danzpunkten är digitalt dansbibliotek i mobilen vilket användare kan få information om kommande dansevenemang via Danzpunkten appen.",
            text: "",
            href: "#",
        },
        {
            heading: "Vad krävs för att installera appen?",
            answer: "Operativsystemet IOS 10 (Iphone) eller Oreo (Android) eller senare versioner.",
            text: "",
            href: "#",
        },
        {
            heading: "Kan jag se danser på min dator?",
            answer: "Nej, Danzpunkten är idag bara tillgänglig i mobiler via Danzpunkten appen.",
            text: "",
            href: "#",
        },
        {
            heading: "Vilka uppgifter spar Danzpunkten om mig?",
            answer: "I Danzpunktens Integritetspolicy kan du läsa om hur vi hanterar dina personuppgifter. Vill du erhålla de personuppgifter Danzpunkten lagrat om just dig? Mejla oss på info@danzpunkten.se så bifogar vi en kopia på dina personuppgifter.",
            text: "Integritetspolicy",
            href: "https://danzpunkten.se/policy.pdf",
        },
        {
            heading: "Kan jag lita på att appen visar korrekt information?",
            answer: "Nej, Danzpunkten hämtar information från flera källor b l a från tredjepart och vi kan därmed inte veta om de alltid är uppdaterade eller relevanta. Danzpunkten lämnar inga som helst garantier på att appens innehåll eller information är korrekt. Vi rekommenderar dig som att alltid kontakta arrangören innan du åker på ett dansevenemang. ",
            text: "",
            href: "#",
        },
        {
            heading: "Kan jag upplysa om en felaktig/inställd dans?",
            answer: "Självklart! För att erbjuda så relevant information som möjligt är vi tacksamma för att du rapporterar in fel, förändringar eller inställda dansevenemang på info@danzpunkten.se. ",
            text: "",
            href: "#",
        },
        {
            heading: "Hur kommer jag igång?",
            answer: "1) Börja med att skapa ett användarkonto. Klicka på “‘Skaffa konto” här på Danzpunktens hemsida. Fyll i formuläret genom att ange en e-postadress, skriv in för-efternamn, ange ditt tiosiffriga födelsedatum (åååå-mm-dd) och välj dansroll. Klicka därefter på “Registrera”. <br><br> 2) När registreringen är klar kommer du få ett mejl från oss, skickat till din angivna e-postadress. I mejlet klickar du på “Välj lösenord”. Ett fönster kommer då öppnas i din webbläsare och i formuläret skriver du in ditt lösenord (sex tecken, minst en bokstav eller siffra). När du har skrivit in ditt angivna lösenord i det andra formulärfältet klickar du på “Registrera” Du kommer nu kunna logga in med dina angivna inloggningsdetaljer (e-postadress, lösenord) på appen och på hemsidan. <br><br> 3) Ladda ner Danzpunkten appen på Playstore (Android) eller Appstore (IPhone). Starta upp Danzpunkten appen och tryck på på “Logga in”. Fyll i den e-postadress och lösenord du angav vid registreringen av kontot och tryck sedan på ”Logga in”. Du kommer nu att landa i Danzpunktens digitala dansbibliotek. ",
            text: "",
            href: "#",
        },
        {
            heading: "Hur skapar jag ett konto?",
            answer: "1) Klicka på “‘Skaffa konto” här på Danzpunktens hemsida. Fyll i formuläret genom att ange en e-postadress, skriv in för-efternamn, ange ditt tiosiffriga födelsedatum (åååå-mm-dd) och välj dansroll. Klicka därefter på “Registrera”. <br><br> 2) När registreringen är klar kommer du få ett mejl från oss skickat till din angivna e-postadress. I mejlet klickar du på “Välj lösenord”. Ett fönster kommer då öppnas i din webbläsare och i formuläret skriver du in ditt lösenord (sex tecken, minst en bokstav eller siffra). När du har skrivit in ditt angivna lösenord i det andra formulärfältet klickar du på “Registrera” Du kommer nu kunna logga in med dina angivna inloggningsdetaljer (e-postadress, lösenord) på appen och på hemsidan.",
            text: "",
            href: "#",
        },
        {
            heading: "Varför behöver jag ett konto?",
            answer: "Du behöver inget konto idag för att nyttja Danzpunkten-appens fulla funktionalitet. Men det kan vara bra att redan nu registrera ett konto eftersom vi löpande planerar att vidareutveckla appen och göra innehållet mer individanpassat.",
            text: "",
            href: "#",
        },
        {
            heading: "Hur ändrar jag mina kontouppgifter?",
            answer: "Klicka på “‘Logga in” i vänstra hörnet, här på Danzpunktens hemsida, fyll i inloggningsdetaljerna och klicka på knappen “Logga in”. Ändra den information du önskar i fälten och klicka på knappen “Spara” i samma kolumner som i fälten du ändrade i.",
            text: "",
            href: "#",
        },
        {
            heading: "Hur ändrar jag e-postadress?",
            answer: "Se: ”Hur ändrar jag jag kontouppgifter?”",
            text: "",
            href: "#",
        },
        {
            heading: "Hur byter jag lösenord?",
            answer: "Se: ”Hur ändrar jag jag kontouppgifter?”",
            text: "",
            href: "#",
        },
        {
            heading: "Jag har glömt mitt lösenord?",
            answer: "Klicka på “‘Logga in” i vänstra hörnet, här på Danzpunktens hemsida, klicka sedan på ”Glömt lösenord”. Inom ett par minuter får du ett meddelande skickat till din e-postadress. klicka på ”Ändra lösenord” ange ett nytt lösenord och klick på ”Spara”.",
            text: "",
            href: "#",
        },
        {
            heading: "Jag har glömt min e-postadress?",
            answer: "1) Om du inte kommer ihåg vilken av dina e-postadresser du uppgav vid registreringstillfället kan du klicka på “‘Logga in” i vänstra hörnet, här på Danzpunktens hemsida, klicka sedan på ”Glömt lösenord” då skickas ett mejl till din angivna e-postadress. <br><br> 2) Om du inte längre har tillgång till din angivna e-postadress behöver du skapa ett nytt konto med en ny e-postadress.",
            text: "",
            href: "#",
        },
        {
            heading: "Finns det en kostnad för att använda appen?",
            answer: "Det kostar ingenting att använda Danzpunkten appen. Vi är tacksamma för alla frivilliga Swish donationer.",
            text: "",
            href: "#",
        },
        {
            heading: "Hur kontaktar jag Danzpunkten?",
            answer: "Du kommer i kontakt med Danzpunkten genom att mejla oss på info@danzpunkten.se. ",
            text: "",
            href: "#",
        },
        {
            heading: "Jag har en idé om en ny appfunktion!",
            answer: "Vad kul! Vi uppskattar att du engagerar dig i vårt utvecklingsarbete till att göra Danzpunkten ännu bättre. Beskriv din idé och skicka den till info@danzpunkten.se. Det glädjer oss att du som engagerar dig och göra appen bättre för dig och andra dansare!",
            text: "",
            href: "#",
        },
        {
            heading: "Någon funktion i appen fungerar inte!",
            answer: "Upplys oss om felet och beskriv vilken funktion det gäller genom att mejla oss på info@danzpunkten.se. Vi lägger stort värde att du som engagerar dig och göra appen bättre för dig och andra dansare!",
            text: "",
            href: "#",
        },
    ]);
    const [open, setOpen] = useState("");
    const [mobile, setMobile] = useState(false);
    const [isShow, setIsShow] = useState(false);
    useEffect(() => {
        console.log("mobile", mobile);
        let w = window.innerWidth;
        if (w < 768) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [mobile, isShow]);
    const clickHandler = (value) => {
        if (value == open) {
            setOpen("");
        } else {
            setOpen(value);
        }
    };
    const answerHandler = (answer) => {
        if (answer.text.length != 0) {
            let htmlcode = (
                <>
                    <Link href={answer.href}>{answer.text}</Link>{" "}
                </>
            );
            let array = answer.answer.split(" ");
            return array.map((item, i) => {
                if (item.includes(answer.text)) {
                    return htmlcode;
                } else {
                    return item + " ";
                }
            });
        } else {
            let text = answer.answer.split(" ");
            let breaks = (
                <>
                    <Linebreak />
                </>
            );
            return text.map((item, i) => {
                if (item == "<br><br>") {
                    return breaks;
                } else {
                    return item + " ";
                }
            });
        }
    };

    const questionsHandler = (question) => {
        let len = question.length;
        let devied = len / 2;
        return (
            <>
                <ContainerItem>
                    {question.slice(0, devied).map((item, index) => (
                        <Item key={index}>
                            <ItemHeading
                                onClick={(e) => clickHandler(item.heading)}
                                color={open == item.heading ? "true" : "false"}
                            >
                                {item.heading}
                            </ItemHeading>
                            {open == item.heading ? (
                                <ItemAnswer>{answerHandler(item)}</ItemAnswer>
                            ) : null}
                        </Item>
                    ))}
                </ContainerItem>
                {mobile ? (
                    isShow ? null : (
                        <Button onClick={(e) => setIsShow((prev) => !prev)}>
                            Visa mer
                        </Button>
                    )
                ) : null}
                {mobile ? (
                    isShow ? (
                        <ContainerItem>
                            {question.slice(devied, len).map((item, index) => (
                                <Item key={index}>
                                    <ItemHeading
                                        onClick={(e) =>
                                            clickHandler(item.heading)
                                        }
                                        color={
                                            open == item.heading
                                                ? "true"
                                                : "false"
                                        }
                                    >
                                        {" "}
                                        {item.heading}{" "}
                                    </ItemHeading>
                                    {open == item.heading ? (
                                        <ItemAnswer>
                                            {answerHandler(item)}
                                        </ItemAnswer>
                                    ) : null}
                                </Item>
                            ))}
                        </ContainerItem>
                    ) : null
                ) : (
                    <ContainerItem>
                        {question.slice(devied, len).map((item, index) => (
                            <Item key={index}>
                                <ItemHeading
                                    onClick={(e) => clickHandler(item.heading)}
                                    color={
                                        open == item.heading ? "true" : "false"
                                    }
                                >
                                    {item.heading}
                                </ItemHeading>
                                {open == item.heading ? (
                                    <ItemAnswer>
                                        {answerHandler(item)}
                                    </ItemAnswer>
                                ) : null}
                            </Item>
                        ))}
                    </ContainerItem>
                )}
                {mobile ? (
                    isShow ? (
                        <Button onClick={(e) => setIsShow((prev) => !prev)}>
                            Visa mindre
                        </Button>
                    ) : null
                ) : null}
            </>
        );
    };
    return (
        <Wrapper>
            <TextHeading>Vanliga Frågor</TextHeading>
            <Container>{questionsHandler(questions)}</Container>
        </Wrapper>
    );
};

export default Questions;
