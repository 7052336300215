import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";

import Header from "../../components/Layout/Header/Header";
import Events from "../../components/Layout/Events/Events";
import Application from "../../components/Layout/Application/Application";
import Background from "../../components/Layout/Background/Background";
import Supportus from "../../components/Layout/Supportus/Supportus";
import Questions from "../../components/Layout/Questions/Questions";
import GetStarted from "../../components/Layout/GetStarted/GetStarted";

import Overlay from "../../components/UI/Overlay/Overlay";
import MobileOverlay from "../../components/UI/Overlay/MobileOverlay";
import useComponentVisible from "../../hooks/useComponentVisible";
import Login from "../../components/Layout/Login/Login";
import Signup from "../../components/Layout/Signup/Signup";
import Gdpr from "../../components/Layout/Gdpr/Gdpr";
import HeaderMobile from "../../components/Layout/HeaderMobile/HeaderMobile";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useLocation } from 'react-router-dom';
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #f3f3f8;
`;
const WrapperContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 1440px;
    height: auto;
    background-color: #f3f3f8;
    margin: 0 auto;
    @media (max-width: 767px) {
        width: 100%;
        margin: 0px;
    }
`;
const Container = styled.div`
    width: 1440px;
    height: auto;
    margin: 0 auto;
    @media (max-width: 1439px) {
        width: 100%;
        margin: 0px;
        overflow: hidden;
    }

`;
const Section = styled.section`
    width: 1152px;
    height: auto;
    margin: 0 auto;
    @media (max-width: 767px) {
        width: 100%;
        margin: 0px;
        padding-left: 18.63px;
        padding-right: 18.63px;
    }
    @media (min-width: 767px) and (max-width: 1195px){
        width:100%;
        padding-left:28.8px;
        padding-right:28.8px;
        margin: 0px;
    }
`;
const Line = styled.div`
    width: 100%;
    height: 21.6px;
    background-color: #34326a;
    position: relative;
`;
const Box = styled.div`
    width: 100%;
    height: 100%;
`;
const ScrollTo = styled.div`
    width: 100%;
    height: 50px;
    margin-bottom: 128px;
    @media (max-width: 767px) {
        display: none;
    }
`;
const Homepage = () => {
    const { ref, isComponentVisible, setIsComponentVisible } =
        useComponentVisible(false);
    const [login, setLogin] = useState(false);
    const [signUp, setSignUp] = useState(false);
    const [toggleGdpr, setToggleGdpr] = useState(false);
    const [gdpr, setGdpr] = useLocalStorage("gdpr", "init");
    const [counter, setCounter] = useState(false);
    const intervalIDRef = useRef(null);
    const [mobile, setMobile] = useState(false);
    const acceptHandler = () => {
        setGdpr("accept");
        console.log("accept");
    };
    const startTimer = useCallback(() => {
        intervalIDRef.current = setInterval(() => {
            setToggleGdpr(true);
        }, 30000);
    }, []);

    const stopTimer = useCallback(() => {
        clearInterval(intervalIDRef.current);
        intervalIDRef.current = null;
        setToggleGdpr(false);
    }, []);
    useEffect(() => {
        return () => clearInterval(intervalIDRef.current);
    }, []);
    const gdprHandler = () => {
        setToggleGdpr(true);
    };
    useEffect(() => {
        if (gdpr === "accept") {
            stopTimer();
        }
        if (gdpr === "denied") {
            startTimer();
        }
        if (gdpr === "init") {
            setToggleGdpr(true);
        }
    }, [gdpr]);
    const exitToggleHandler = () => {
        setToggleGdpr(false);
        setCounter(true);
        setGdpr("denied");
        setUserEmailAlreadyExists("");
    };
    const signupHandler = () => {
        setIsComponentVisible(true);
        setSignUp(true);
        setLogin(false);
        setSignUpHeight("538.445");
        setUserEmailAlreadyExists("");
    };
    const loginHandler = () => {
        setIsComponentVisible(true);
        setLogin(true);
        setSignUp(false);
        setLoginHeight("393.179375");
        setUserEmailAlreadyExists("");
    };
    useEffect(() => {
        if (!isComponentVisible) {
            setLogin(false);
            setSignUp(false);
        }
    }, [isComponentVisible]);
    const exitHandler = () => {
        setLogin(false);
        setSignUp(false);
        setIsComponentVisible(false);
        setUserEmailAlreadyExists("");
    };
    const signupHandlerExit = () => {
        setSignUpHeight("538.445");
        setLogin(false);
        setSignUp(false);
        setIsComponentVisible(false);
        setUserEmailAlreadyExists("");
    };
    const loginHandlerExit = () => {
        setLoginHeight("393.179375");
        setLogin(false);
        setSignUp(false);
        setIsComponentVisible(false);
        setUserEmailAlreadyExists("");
    };
    const scrollToApp = useRef();
    const scrollToStart = useRef();
    const scrollToAboutUs = useRef();
    const scrollToSupportUs = useRef();
    const scrollToQuestions = useRef();
    const scrollToGetStarted = useRef();
    const scrollToNextHandler = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };
    const [signUpHeight, setSignUpHeight] = useState("538.445");
    const [loginHeight, setLoginHeight] = useState("393.179375");
    const [userEmailAlreadyExists, setUserEmailAlreadyExists] = useState("");
    const userAlreadyExists = (email) => {
        setIsComponentVisible(true);
        setLogin(true);
        setSignUp(false);
        setLoginHeight("393.179375");
        setUserEmailAlreadyExists(email);
    };
    useEffect(() => {
        let w = window.innerWidth;
        if (w < 767) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [mobile]);
    const location = useLocation();
    useEffect(() => {
        if(location.hash == "#skaffa"){
            setIsComponentVisible(true);
            setSignUp(true);
        }
    });
    return (
        <Wrapper>
            {toggleGdpr ? (
                <Gdpr
                    exitHandler={exitToggleHandler}
                    acceptHandler={acceptHandler}
                />
            ) : null}
            {mobile ? (
                login || signUp ? (
                    <MobileOverlay>
                        {isComponentVisible && login ? (
                            <Box ref={ref}>
                                <Login
                                    signupHandler={signupHandler}
                                    exitHandler={loginHandlerExit}
                                    setLoginHeight={setLoginHeight}
                                    userEmailAlreadyExists={
                                        userEmailAlreadyExists
                                    }
                                />
                            </Box>
                        ) : null}
                        {isComponentVisible && signUp ? (
                            <Box ref={ref}>
                                <Signup
                                    exitHandler={exitHandler}
                                    setSignUpHeight={setSignUpHeight}
                                    loginHandler={loginHandler}
                                    signupHandlerExit={signupHandlerExit}
                                    userAlreadyExists={userAlreadyExists}
                                />
                            </Box>
                        ) : null}
                    </MobileOverlay>
                ) : null
            ) : login || signUp ? (
                <Overlay height={login ? loginHeight : signUpHeight}>
                    {isComponentVisible && login ? (
                        <Box ref={ref}>
                            <Login
                                signupHandler={signupHandler}
                                exitHandler={loginHandlerExit}
                                setLoginHeight={setLoginHeight}
                                userEmailAlreadyExists={userEmailAlreadyExists}
                            />
                        </Box>
                    ) : null}
                    {isComponentVisible && signUp ? (
                        <Box ref={ref}>
                            <Signup
                                exitHandler={exitHandler}
                                setSignUpHeight={setSignUpHeight}
                                loginHandler={loginHandler}
                                signupHandlerExit={signupHandlerExit}
                                userAlreadyExists={userAlreadyExists}
                            />{" "}
                        </Box>
                    ) : null}
                </Overlay>
            ) : null}
            <WrapperContainer>
                {mobile ? (
                    <HeaderMobile
                        login={login}
                        signup={signUp}
                        gdpr={gdpr}
                        gdprHandler={gdprHandler}
                        loginHandler={loginHandler}
                        signupHandler={signupHandler}
                    />
                ) : (
                    <Header
                        gdpr={gdpr}
                        gdprHandler={gdprHandler}
                        loginHandler={loginHandler}
                        signupHandler={signupHandler}
                        scrollToNextHandler={scrollToNextHandler}
                        scrollToStart={scrollToStart}
                        scrollToApp={scrollToApp}
                        scrollToAboutUs={scrollToAboutUs}
                        scrollToSupportUs={scrollToSupportUs}
                        scrollToQuestions={scrollToQuestions}
                        scrollToGetStarted={scrollToGetStarted}
                    />
                )}

                <Container>
                    <Section>
                        <Events />
                        <Application scrollToApp={scrollToApp} />
                        <ScrollTo ref={scrollToAboutUs} />
                        <Background />
                        <ScrollTo ref={scrollToSupportUs} />
                        <Supportus />
                        <ScrollTo ref={scrollToQuestions} />
                        <Questions />
                        <ScrollTo ref={scrollToGetStarted} />
                        <GetStarted />
                    </Section>
                </Container>
            </WrapperContainer>
        </Wrapper>
    );
};
export default Homepage;
