import React, { useState } from "react";
import styled from "styled-components";
import supportus from "../../../assets/supportus.png";
import swish from "../../../assets/swish.png";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 614.25px;
    @media (max-width: 767px) {
        padding-left: 28.8px;
        padding-right: 28.8px;
        height: auto;
        margin-top:160px;
    }
`;
const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
const Image = styled.img`
    width: 202.876875px;
    height: 203.535px;
    @media (max-width: 767px) {
        width: 141.2438055555556px;
        height: 141.6695555555556px;
    }
`;
const TextHeading = styled.h2`
    padding: 0px 0px 0px 0px;
    margin: 14.4px 0px 0px 0px;
    color: #66659c;
    font-size: 33.75px;
    font-weight: 700;
    @media (max-width: 767px) {
        font-size: 25.62px;
        text-align: left;
    }
`;
const TextParagraph = styled.p`
    margin: 14.4px 0px 0px 0px;
    padding: 0px;
    color: #4d4d4d;
    font-size: 19.805625px;
    font-weight: 400;
    text-align: center;
    @media (max-width: 767px) {
        font-size: 16.86562px;
        & br {
            display:none;
        }
    }
`;
const SwishContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 28.8px;
    width: 300.864375px;
    height: 80.375625px;
    border-radius: 15px;
    box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    @media (max-width: 767px) {
        margin-top: 18.63px;
        width: 213.63325px;
        height: 65.99608333333333px;

    }
`;
const SwishImage = styled.img`
    width: 91.40625px;
    height: 27.7875px;
    margin-left: 28.8px;

    @media (max-width: 767px) {
        margin-left: 18.63px;
        width: 59.13194444444444px;
        height: 17.97611111111111px;
    }
`;
const SwishText = styled.div`
    color: #4d4d4d;
    font-size: 19.805625px;
    font-weight: 400;
    margin-left: 14.4px;
    @media (max-width: 767px) {
        font-size: 15.86562px;
        margin-left: 9.3px;
    }
`;
const Supportus = ({ scrollToSupportUs }) => {
    return (
        <Wrapper ref={scrollToSupportUs}>
            <ImageContainer>
                <Image src={supportus} />
            </ImageContainer>
            <TextHeading>Stöd oss</TextHeading>
            <TextParagraph>
                Om du delar initiativet ber vi dig att stödja oss. <br />
                Ditt bidrag gör skillnad!
            </TextParagraph>
            <SwishContainer>
                <SwishImage src={swish} />
                <SwishText>123 042 24 02</SwishText>
            </SwishContainer>
        </Wrapper>
    );
};
export default Supportus;
