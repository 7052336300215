import "../../index.css";

import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    defer,
    useParams,
} from "react-router-dom";
import Account from "../../pages/Account/Account";
import Homepage from "../../pages/Homepage/Homepage";
import Password from "../../pages/Password/Password";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import Faq from "../../pages/Faq/Faq";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import { AuthLayout } from "../../components/Layout/AuthLayout/AuthLayout";


const getUserData = () =>
    new Promise((resolve) => {
        let user = {
            token: "",
            gdpr: "",
        };
        user.token = ''
        user.gdpr = window.localStorage.getItem("gdpr");
        resolve(user);
    });

export const router = createBrowserRouter([
    {
        path: "/",
        element: <AuthLayout />,
        errorElement: <ErrorPage heading="Fel 404." message="Vi ber om ursäkt, sidan hittades ej!" />,
        loader: () => defer({ userPromise: getUserData() }),
        children: [
            {
                path: "",
                element: <Homepage />,
                errorElement: <ErrorPage heading="Fel 404." message="Vi ber om ursäkt, sidan hittades ej!" />,
            },
            {
                path: "/faq",
                element: <Faq />,
                errorElement: <ErrorPage heading="Fel 404." message="Vi ber om ursäkt, sidan hittades ej!" />,
            },
            {
                path: "/account",
                element: <Account />,
                errorElement: <ErrorPage heading="Fel 404." message="Vi ber om ursäkt, sidan hittades ej!" />,
            },
            {
                path: "/privacy_policy",
                element: <PrivacyPolicy />,
                errorElement: <ErrorPage heading="Fel 404." message="Vi ber om ursäkt, sidan hittades ej!" />
            },
            {
                path: "/password/:token",
                element: <Password />,
                errorElement: <ErrorPage heading="Fel 404." message="Vi ber om ursäkt, sidan hittades ej!" />,
            },
            
        ],
    },
]);
