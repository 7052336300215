import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
    const [token, setToken] = useLocalStorage("token", userData.token);
    const navigate = useNavigate();

    const login = async (data) => {
        setToken(data);
    };

    const logout = () => {
        setToken(null);
    };

    const value = useMemo(
        () => ({
            token,
            login,
            logout,
        }),
        [token]
    );

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
