import React, { useState, useEffect } from "react";
import styled from "styled-components";
import bg from "../../../assets/bg.png";
import bg2 from "../../../assets/bg2.png";
import logo from "../../../assets/logo.png";
import phone from "../../../assets/phone.png";
import googleplay from "../../../assets/googleplay.png";
import appstore from "../../../assets/appstore.png";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useScrollPosition from "../../../hooks/useScrollPosition";
import { urlUserLogout, urlEventCount } from "../../../helpers/url.js";
import axios from "axios";
const Wrapper = styled.header`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 790.3px;
    background-color: #34326a;
    @media (max-width: 1195px) {
        width:100%;
        height:auto;
    }
`;
const StatusContainer = styled.div`
    position: fixed;
    top: 0;
    min-width: 100%;
    width: 100%;
    max-width: 1440px;
    height: 43.2px;
    background-color: #34326a;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    z-index: 2;
    @media (max-width: 767px) {
        height: 55.806px;
    }
`;
const ButtonContainer = styled.div`
    grid-column-start: ${(props) => props.start};
    grid-column-end: ${(props) => props.end};
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify};
`;
const Button = styled.div`
    font-size: 15.69375px;
    cursor: pointer;
    color: #66659c;
    margin-left: ${(props) => props.left};
    margin-right: ${(props) => props.right};
`;
const LogoContainer = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Logo = styled.img`
    width: 158.9px;
`;
const NavContainer = styled.div`
    position: fixed;
    top: 43.2px;
    width: 100%;
    height: 72px;
    background-color: #f3f3f8;
    z-index: 2;
    box-shadow: -1px 5px 12px -4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: -1px 7px 12px -4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -1px 5px 12px -4px rgba(0, 0, 0, 0.2);
    @media (max-width: 767px) {
        width:100%;
        top: 55.806px;
    }
`;
const Nav = styled.div`
    display: grid;
    width:100%;
    max-width: 1152px;
    margin: 0 auto;
    height: 72px;
    grid-template-columns: 864px 288px;
    grid-template-rows: 1fr;
    @media (max-width: 767px) {
        grid-template-columns: 1fr 116.7px 28.8px;
        grid-template-rows: 1fr;
    }
    @media (min-width: 768px) and (max-width: 1195px){
        grid-template-columns: 70% 30%;
        width:100%;
        max-width: 100%;
        padding-left: 28.8px;
        padding-right: 28.8px;
    }
`;
const Menu = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    @media (max-width: 767px) {
        display:none;
    }
    
`;
const NavItem = styled.div`
    display: flex;
    padding-left: 1.6667%;
    padding-right: 1.6667%;
    color: #66659c;
    font-size: 13.921875px;
    align-items: center;
    border-bottom: 2px solid
        ${(props) => (props.color == "true" ? `#66659c` : `#f3f3f8`)};
    cursor: pointer;
`;
const RegContainer = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const Reg = styled.div`
    width: 116.7px;
    height: 43.3px;
    line-height: 43.3px;
    background-color: ${(props) =>
        props.gdpr === "accept" ? `#f09818` : `#cccbdd`};
    border-radius: 11.25px;
    color: #f3f3f8;
    font-size: 15.69375px;
    cursor: pointer;
    text-align: center;
    &:hover {
        background-color: #f09818;
    }
`;
const Section = styled.div`
    margin-top: 115.2px;
    width: 100%;
    height: 630px;
    background-color: #34326a;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    @media (max-width: 767px) {
        margin-top: 127.806px;
        background-image: url(${bg2});
        background-size: 100% 100%;
        width:100%;
        height: 545px;
    }
`;
const Article = styled.div`
    width: 100%;
    max-width: 1152px;
    margin: 0 auto;
    height: 100%;
    display: flex;
`;
const PhoneImageWrapper = styled.div`
    width: 398.655px;
    height: 517.091286px;
    margin-left: 145.125px;
    margin-top: 67.5px;
    @media (max-width: 1195px) {
        display: none;
    }
`;
const Image = styled.img`
    width: 100%;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 86.4px;
    margin-top: 210.9375px;
    width: 532.794375px;
    @media (max-width: 1195px) {
        width: 100%;
        margin-left: 0px;
        text-align: center;
        margin-top:160px;
    }
`;
const HeadingContainer = styled.div`
    width: 532.794375px;
    height: 115.3125px;
    @media (max-width: 1195px) {
        width: 100%;
        height:auto;
        padding-left:28.8px;
        padding-right:28.8px;
        text-align: center;
    }
`;
const TextHeading = styled.h1`
    padding: 0px;
    margin: 0px;
    font-size: 45px;
    font-weight: 700;
    color: #f2f2f7;
    line-height: 53px;
    @media (max-width: 767px) {
        font-size:31.5px;
        line-height: 40px;
    }
`;
const TextParagraph = styled.p`
    margin: 3px 0 0 0;
    padding: 0px;
    font-size: 19.805625px;
    color: #f2f2f7;
    @media (max-width: 767px) {
        margin: 7.2px 0 0 0;
        font-size: 16.86562px;
    }
`;
const StoreContainer = styled.div`
    display: flex;
    @media (max-width: 1195px) {
        width:100%;
        justify-content:center;
    }
`;
const GooglePlay = styled.img`
    width: 141.654375px;
    height: 41.97375px;
    margin-top: 28.8px;
    cursor: pointer;
`;
const SpaceStore = styled.div`
    width: 28.8px;
`
const AppStore = styled.img`
    height: 41.97375px;
    margin-top: 28.8px;
    cursor: pointer;
    `;
const EventBox = styled.div`
    position: absolute;
    display: flex;
    top: 737.3px;
    width: 409px;
    height: 106px;
    border-radius: 15px;
    background-color: #f2f2f7;
    margin: 0 auto;
    z-index: 1;
    box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    @media (max-width: 767px) {
        width: auto;
        height: auto;
        top:570px;
    }
`;
const EventItem = styled.div`
    padding: 28.8px;
    &:nth-child(2) {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 28.8px;
        padding-bottom: 28.8px;
    }
    @media (max-width: 767px) {
        padding: 18.63px;
        &:nth-child(2) {
            padding-left: 0px;
            padding-right: 0px;
            padding-top: 18.63px;
            padding-bottom: 18.63px;
        }
    }
`;
const EventHeading = styled.div`
    font-size: 18.961875px;
    color: #66659c;
    font-weight: 700;
    text-align: center;
    @media (max-width: 767px) {
        font-size:17.271875px;
    }
`;
const EventValue = styled.div`
    font-size: 23.90625px;
    color: #333333;
    font-family: Open + Sans;
    text-align: center;
    
    @media (max-width: 767px) {
        font-size:20.73625px;
    }
`;

const Header = ({
    loginHandler,
    signupHandler,
    gdprHandler,
    scrollToNextHandler,
    scrollToApp,
    scrollToAboutUs,
    scrollToSupportUs,
    scrollToQuestions,
    scrollToGetStarted,
    scrollToStart,
    gdpr,
}) => {
    const [clicked, setClicked] = useState("start");

    const navItemHandler = (item, ref) => {
        scrollToNextHandler(ref);
    };
    const navigate = useNavigate();
    const accountHandler = () => {
        navigate("/account", { replace: true });
    };
    const { token } = useAuth();
    const scrollPosition = useScrollPosition();
    const [events, setEvents] = useState("");
    const [places, setPlaces] = useState("");
    const [musicians, setMusicians] = useState("");
    const countHandler = async () => {
        try {
            const response = await axios.get(urlEventCount);
            setEvents(response.data.allActiveEvents);
            setPlaces(response.data.allActivePlacesLen);
            setMusicians(response.data.allActiveMusiciansLen)
        } catch (err) {
            console.log("err", err);
        }
    };
    
    useEffect(() => {
        countHandler();
    }, []);
    useEffect(() => {
        if (scrollPosition >= 0 && scrollPosition <= 1499) {
            setClicked("start");
        }
        if (scrollPosition >= 1500 && scrollPosition <= 3417) {
            setClicked("appen");
        }
        if (scrollPosition >= 3420 && scrollPosition <= 4210) {
            setClicked("bakgrund");
        }

        if (scrollPosition >= 4216 && scrollPosition <= 5117) {
            setClicked("stodoss");
        }
        if (scrollPosition >= 5120 && scrollPosition <= 6017) {
            setClicked("vanliga");
        }
        if (scrollPosition >= 6020) {
            setClicked("kom");
        }
    }, [scrollPosition]);
    const logoutHandler = async () => {
        try {
            const response = await axios.delete(urlUserLogout);
            if (response.status === 200) {
                navigate("/", { replace: true });
                logout();
            }
        } catch (err) {
            console.log("err", err);
        }
    };
    const { logout } = useAuth();
    
    return (
        <Wrapper ref={scrollToStart}>
            <StatusContainer>
                <ButtonContainer start={1} end={2} justify="flex-start">
                    <Button
                        left="10vw"
                        right="0"
                        onClick={
                            token == null || token.length == 0
                                ? gdpr === "accept"
                                    ? loginHandler
                                    : gdprHandler
                                : accountHandler
                        }
                    >
                        {token == null || token.length == 0 ? "Logga in" : "Mitt konto"}
                    </Button>
                </ButtonContainer>
                <LogoContainer>
                    <Logo src={`${logo}`} />
                </LogoContainer>
                <ButtonContainer start={3} end={4} justify="flex-end">
                    <Button
                        left="0vw"
                        right="10vw"
                        onClick={token == null || token.length == 0 ? "" : logoutHandler}
                    >
                        {token == null || token.length == 0 ? "" : "Logga ut"}
                    </Button>
                </ButtonContainer>
            </StatusContainer>
            <NavContainer>
                <Nav>
                    <Menu>
                        <NavItem
                            onClick={() =>
                                navItemHandler("start", scrollToStart)
                            }
                            color={clicked == "start" ? "true" : "false"}
                        >
                            Start
                        </NavItem>
                        <NavItem
                            onClick={() => navItemHandler("appen", scrollToApp)}
                            color={clicked == "appen" ? "true" : "false"}
                        >
                            Appen
                        </NavItem>
                        <NavItem
                            onClick={() =>
                                navItemHandler("bakgrund", scrollToAboutUs)
                            }
                            color={clicked == "bakgrund" ? "true" : "false"}
                        >
                            Bakgrund
                        </NavItem>
                        <NavItem
                            onClick={() =>
                                navItemHandler("stodoss", scrollToSupportUs)
                            }
                            color={clicked == "stodoss" ? "true" : "false"}
                        >
                            Stöd oss
                        </NavItem>
                        <NavItem
                            onClick={() =>
                                navItemHandler("vanliga", scrollToQuestions)
                            }
                            color={clicked == "vanliga" ? "true" : "false"}
                        >
                            Vanliga frågor
                        </NavItem>
                        <NavItem
                            onClick={() =>
                                navItemHandler("kom", scrollToGetStarted)
                            }
                            color={clicked == "kom" ? "true" : "false"}
                        >
                            Kom igång
                        </NavItem>
                    </Menu>
                    <RegContainer>
                        {token == null ? (
                            <Reg
                                gdpr={gdpr}
                                onClick={
                                    gdpr === "accept"
                                        ? signupHandler
                                        : gdprHandler
                                }
                            >
                                Skaffa konto
                            </Reg>
                        ) : (
                            ""
                        )}
                    </RegContainer>
                </Nav>
            </NavContainer>
            <Section>
                <Article>
                    <PhoneImageWrapper>
                        <Image src={phone} />
                    </PhoneImageWrapper>
                    <TextContainer>
                        <HeadingContainer>
                            <TextHeading>
                                FÖR DANSEN I TAKT <br />MED TIDEN
                            </TextHeading>
                        </HeadingContainer>

                        <TextParagraph>
                            Ladda ner appen på Google Play eller App Store
                        </TextParagraph>
                        <StoreContainer>
                            <a href="https://play.google.com/store/apps/details?id=se.danzpunkten" target="_blank"><GooglePlay src={googleplay} /></a>
                            <SpaceStore />
                            <a href="https://apps.apple.com/se/app/danzpunkten/id1635472519?l=sv" target="_blank"><AppStore src={appstore} /></a>
                        </StoreContainer>
                    </TextContainer>
                </Article>
            </Section>
            <EventBox>
                <EventItem>
                    <EventHeading>Evenemang</EventHeading>
                    <EventValue>{events}</EventValue>
                </EventItem>
                <EventItem>
                    <EventHeading>Dansställen</EventHeading>
                    <EventValue>{places}</EventValue>
                </EventItem>
                <EventItem>
                    <EventHeading>Dansband</EventHeading>
                    <EventValue>{musicians}</EventValue>
                </EventItem>
            </EventBox>
        </Wrapper>
    );
};
export default Header;
