import React, { useState, useEffect } from "react";
import styled from "styled-components";
import bg from "../../../assets/bg.png";
import bg2 from "../../../assets/bg2.png";
import logo from "../../../assets/logo.png";
import phone from "../../../assets/phone.png";
import googleplay from "../../../assets/googleplay.png";
import appstore from "../../../assets/appstore.png";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useScrollPosition from "../../../hooks/useScrollPosition";
import { urlUserLogout, urlEventCount } from "../../../helpers/url.js";
import axios from "axios";
const Wrapper = styled.header`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: #34326a;
    @media (max-width: 1195px) {
        width:100%;
        height:auto;
    }
`;
const StatusContainer = styled.div`
    position: fixed;
    top: 0;
    min-width: 100%;
    width: 100%;
    max-width: 1440px;
    height: 43.2px;
    background-color: #34326a;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    z-index: 2;
    @media (max-width: 767px) {
        height: 55.806px;
    }
`;
const ButtonContainer = styled.div`
    grid-column-start: ${(props) => props.start};
    grid-column-end: ${(props) => props.end};
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify};
`;
const Button = styled.div`
    font-size: 15.69375px;
    cursor: pointer;
    color: #66659c;
    margin-left: ${(props) => props.left};
    margin-right: ${(props) => props.right};
`;
const LogoContainer = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Logo = styled.img`
    width: 158.9px;
`;
const NavContainer = styled.div`
    position: fixed;
    top: 43.2px;
    width: 100%;
    height: 72px;
    background-color: #f3f3f8;
    z-index: 2;
    box-shadow: -1px 5px 12px -4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: -1px 7px 12px -4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -1px 5px 12px -4px rgba(0, 0, 0, 0.2);
    @media (max-width: 767px) {
        width:100%;
        top: 55.806px;
    }
`;
const Nav = styled.div`
    display: grid;
    width:100%;
    max-width: 1152px;
    margin: 0 auto;
    height: 72px;
    grid-template-columns: 864px 288px;
    grid-template-rows: 1fr;
    @media (max-width: 767px) {
        grid-template-columns: 1fr 116.7px 28.8px;
        grid-template-rows: 1fr;
    }
    @media (min-width: 768px) and (max-width: 1195px){
        grid-template-columns: 70% 30%;
        width:100%;
        max-width: 100%;
        padding-left: 28.8px;
        padding-right: 28.8px;
    }
`;
const Menu = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    @media (max-width: 767px) {
        display:none;
    }
    
`;
const NavItem = styled.div`
    display: flex;
    padding-left: 1.6667%;
    padding-right: 1.6667%;
    color: #66659c;
    font-size: 13.921875px;
    align-items: center;
    border-bottom: 2px solid
        ${(props) => (props.color == "true" ? `#66659c` : `#f3f3f8`)};
    cursor: pointer;
`;
const RegContainer = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const Reg = styled.div`
    width: 116.7px;
    height: 43.3px;
    line-height: 43.3px;
    background-color: ${(props) =>
        props.gdpr === "accept" ? `#f09818` : `#cccbdd`};
    border-radius: 11.25px;
    color: #f3f3f8;
    font-size: 15.69375px;
    cursor: pointer;
    text-align: center;
    &:hover {
        background-color: #f09818;
    }
`;


const FaqHeader = ({
    loginHandler,
    signupHandler,
    gdprHandler,
    scrollToNextHandler,
    scrollToApp,
    scrollToAboutUs,
    scrollToSupportUs,
    scrollToQuestions,
    scrollToGetStarted,
    scrollToStart,
    gdpr,
}) => {
    const [clicked, setClicked] = useState("vanliga");

    const navItemHandler = (item, ref) => {
        scrollToNextHandler(ref);
    };
    const navigate = useNavigate();
    const accountHandler = () => {
        navigate("/account", { replace: true });
    };
    const { token } = useAuth();

    const logoutHandler = async () => {
        try {
            const response = await axios.delete(urlUserLogout);
            if (response.status === 200) {
                navigate("/", { replace: true });
                logout();
            }
        } catch (err) {
            console.log("err", err);
        }
    };
    const { logout } = useAuth();
    
    return (
        <Wrapper ref={scrollToStart}>
            <StatusContainer>
                <ButtonContainer start={1} end={2} justify="flex-start">
                    <Button
                        left="10vw"
                        right="0"
                        onClick={
                            token == null
                                ? gdpr === "accept"
                                    ? loginHandler
                                    : gdprHandler
                                : accountHandler
                        }
                    >
                        {token == null ? "Logga in" : "Mitt konto"}
                    </Button>
                </ButtonContainer>
                <LogoContainer>
                    <Logo src={`${logo}`} />
                </LogoContainer>
                <ButtonContainer start={3} end={4} justify="flex-end">
                    <Button
                        left="0vw"
                        right="10vw"
                        onClick={token == null ? "" : logoutHandler}
                    >
                        {token == null ? "" : "Logga ut"}
                    </Button>
                </ButtonContainer>
            </StatusContainer>
            <NavContainer>
                <Nav>
                    <Menu>
                        <NavItem
                            onClick={() =>
                                navigate("/", { replace: true })
                            }
                            color={clicked == "start" ? "true" : "false"}
                        >
                            Start
                        </NavItem>
                        <NavItem
                            onClick={() =>
                                navItemHandler("vanliga", scrollToQuestions)
                            }
                            color={clicked == "vanliga" ? "true" : "false"}
                        >
                            Vanliga frågor
                        </NavItem>
                    </Menu>
                    <RegContainer>
                        {token == null ? (
                            <Reg
                                gdpr={gdpr}
                                onClick={
                                    gdpr === "accept"
                                        ? signupHandler
                                        : gdprHandler
                                }
                            >
                                Skaffa konto
                            </Reg>
                        ) : (
                            ""
                        )}
                    </RegContainer>
                </Nav>
            </NavContainer>
        </Wrapper>
    );
};
export default FaqHeader;
