import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Overlay from "../../UI/Overlay/Overlay";
import exit from "../../../assets/exit.png";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
`;
const Header = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
const TextHeading = styled.h2`
    padding: 0px;
    margin: 28.8px 0px 0px;
    font-weight: 700;
    font-size: 25.3125px;
    color: #f3f3f8;
`;
const Exit = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35.443125px;
    height: 35.443125px;
    cursor: pointer;
    position: absolute;
    z-index: 6;
    top: 14.4px;
    right: 14.4px;
    border-radius: 10px;
    border: solid 2px rgba(237, 236, 242, 0.5);
`;
const Image = styled.img`
    width: 14.23125px;
    height: 14.23125px;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 28.8px;
    align-items: center;
`;
const TextParagraph = styled.p`
    font-size: 13.921875px;
    font-family: Nunito;
    font-weight: 400;
    color: rgba(243, 243, 248, 0.5);
    text-align: center;
`;
const ButtonContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;
const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 43.2px;
    margin-top: 28.8px;
    width: 154.36125px;
    height: 43.2px;
    font-size: 13.921875px;
    font-family: Nunito;
    font-weight: 400;
    color: #f3f3f8;
    background-color: #9d9cbd;
    border-radius: 21.6px;
    cursor: pointer;
`;
const Gdpr = ({ exitHandler, acceptHandler}) => {
    const [mobile, setMobile] = useState(false);
    useEffect(() => {
        let w = window.innerWidth;
        if (w < 767) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [mobile]);
    return (
        <Overlay height={mobile ? "503.179375": "393.179375"} padding="28.8px">
            <Wrapper>
                <Header>
                    <Exit onClick={exitHandler}>
                        <Image src={exit} />
                    </Exit>
                    <TextHeading>Kakor</TextHeading>
                </Header>
                <Content>
                    <TextParagraph>
                        Vi vill använda kakor (cookies) för att förbättra
                        webbplatsen och din användarupplevelse. Om du väljer att
                        avstå ifrån att använda våra kakor så kommer
                        webbplatsens funktion bli begränsad.
                    </TextParagraph>
                    <TextParagraph>
                        De kakor vi använder idag är en försthandskaka och en
                        tredjepartskaka. försthandskaka användas för inloggnings
                        funktionen ska fungera. Tredjepartskakan, det vill säga
                        en kaka från en annan domän än denna. Den kakan används
                        för att samla in statistik.
                    </TextParagraph>
                    <ButtonContainer>
                        <Button onClick={acceptHandler}>Acceptera kakor</Button>
                    </ButtonContainer>
                </Content>
            </Wrapper>
        </Overlay>
    );
};
export default Gdpr;
