import React, { useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    padding-top: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 11;
`;
const Box = styled.div`
    display:flex;
    width: 100%;
    height: 100%;
    background-color: #34326a;
`;
const MobileOverlay = ({ children }) => {
    return (
        <Wrapper>
            <Box>{children}</Box>
        </Wrapper>
    );
};
export default MobileOverlay;
