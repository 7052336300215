import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useParams, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import Overlay from "../../components/UI/Overlay/Overlay";
import exit from "../../assets/exit.png";
import { urlUserGetPre, urlUserCreatePassword } from "../../helpers/url.js";
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #edecf2;
`;
const WrapperContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1152px;
    width: 100%;
    margin: 0 auto;
`;
const StatusContainer = styled.div`
    position: fixed;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: 43.2px;
    background-color: #34326a;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    z-index: 2;
`;
const ButtonContainer = styled.div`
    grid-column-start: ${(props) => props.start};
    grid-column-end: ${(props) => props.end};
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify};
`;
const Button = styled.div`
    font-size: 15.69375px;
    cursor: pointer;
    color: #66659c;
    margin-left: ${(props) => props.left};
    margin-right: ${(props) => props.right};
`;
const LogoContainer = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Logo = styled.img`
    width: 158.9px;
`;
const NavContainer = styled.div`
    position: fixed;
    top: 43.2px;
    min-width: 100%;
    width: 100%;
    height: 72px;
    background-color: #f3f3f8;
    z-index: 2;
    box-shadow: -1px 5px 12px -4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: -1px 7px 12px -4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -1px 5px 12px -4px rgba(0, 0, 0, 0.2);
`;
const Nav = styled.div`
    width: 1152px;
    margin: 0 auto;
    height: 72px;
`;
const Menu = styled.div`
    width: 100%;
    display: flex;
    line-height: 72px;
`;

const RegContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const Heading = styled.h2`
    font-family: Nunito;
    font-weight: 700;
    color: #4d4d4d;
    text-indent: 0;
    font-size: 25.3125px;
    padding: 0px 0px 0px 28.8px;
    margin: 0px 0px 0px 0px;
    text-transform: capitalize;
`;
const Section = styled.section`
    margin-top: ${(props) => props.top};
    width: 100%;
    height: ${(props) => props.height};
    background-color: #f3f3f8;
`;
const UnderHeading = styled.h3`
    margin: 28.8px 0px 0px 28.8px;
    padding: 0px;
    font-family: Nunito;
    font-weight: 700;
    font-size: 18.984375px;
    color: #4d4d4d;
`;
const InputContainer = styled.div`
    width: 100%;
    padding-left: 14.4px;
    padding-right: 14.4px;
`;
const Input = styled.input`
    width: 100%;
    height: 43.678125px;
    border-radius: 21.8390625px;
    box-sizing: border-box;
    background-color: #edecf2;
    border: ${(props) =>
        props.statusCode == 302
            ? `solid 1.5px #F47060`
            : `solid 1.5px #edecf2`};
    padding-left: 14.4px;
    font-family: Nunito;
    font-weight: 400;
    font-size: 12.65625px;
    color: #4e4d4d;
    &:focus {
        outline: none;
        border: solid 1.5px #9d9cbd;
        color: #4e4d4d;
    }
`;
const EmailInput = styled.input`
    width: 100%;
    height: 43.678125px;
    border-radius: 21.8390625px;
    box-sizing: border-box;
    background-color: #f3f3f8;
    border: solid 1.5px #f3f3f8;
    padding-left: 14.4px;
    font-family: Nunito;
    font-weight: 400;
    font-size: 12.65625px;
    color: rgba(78, 78, 78, 0.5);
    &:focus {
        outline: none;
        border: solid 1.5px #f3f3f8;
        color: rgba(78, 78, 78, 0.5);
    }
`;
const Label = styled.div`
    font-family: Nunito;
    font-weight: 400;
    font-size: 15.1875px;
    color: #4e4d4d;
    margin-top: ${(props) => props.top};
    margin-left: 14.4px;
    margin-bottom: 7.2px;
`;
const SaveBtn = styled.div`
    width: 151.4025px;
    height: 43.2px;
    border-radius: 21.6px;
    font-family: Nunito;
    font-weight: 400;
    font-size: 13.921875px;
    color: #f3f3f8;
    background-color: ${(props) => (props.hasChanged ? `#9d9cbd` : `#CCCBDD`)};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28.8px;
    cursor: pointer;
`;
const PaddingBottom = styled.div`
    padding-bottom: 100px;
`;
const WrapperOverlay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 5;
`;
const HeaderOverlay = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
const TextHeadingOverlay = styled.h2`
    padding: 0px;
    margin: 28.8px 0px 0px;
    font-weight: 700;
    font-size: 25.3125px;
    color: #f3f3f8;
`;
const Exit = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35.443125px;
    height: 35.443125px;
    cursor: pointer;
    position: absolute;
    z-index: 6;
    top: 14.4px;
    right: 14.4px;
    border-radius: 10px;
    border: solid 2px rgba(237, 236, 242, 0.5);
`;
const Image = styled.img`
    width: 14.23125px;
    height: 14.23125px;
`;
const ContentOverlay = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 28.8px;
    align-items: center;
`;
const TextParagraphOverlay = styled.p`
    font-size: 13.921875px;
    font-family: Nunito;
    font-weight: 400;
    color: rgba(243, 243, 248, 0.5);
    padding: 0px;
    margin: 0px;
    text-align:center;
`;
const Password = () => {
    const [inputs, setInputs] = useState({
        firstName: "",
        lastName: "",
        passwordNew: "",
        passwordNewAgain: "",
        dirtyFields: [],
    });
    const [save, setSave] = useState(false);
    let { token } = useParams();
    const [statusCode, setStatusCode] = useState();
    const [mobile, setMobile] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        getUsers();
    }, []);
    const getUsers = async () => {
        const decoded = jwt_decode(token);
        const currentDate = new Date();
        try {
            if (decoded.exp * 1000 > currentDate.getTime()) {
                const response = await axios.get(urlUserGetPre, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status == 200) {
                    setInputs({
                        firstName: response.data.user.firstName,
                        lastName: response.data.user.lastName,
                        email: response.data.user.email,
                        passwordNew: "",
                        passwordNewAgain: "",
                        dirtyFields: [],
                    });
                    setSave(false);
                } else {
                    console.log("error");
                }
            } else {
                navigate("/", { replace: true });
            }
        } catch (err) {
            if (err.response.status == 401) {
                navigate("/", { replace: true });
            }
            console.log("uhm", err);
        }
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({
            ...values,
            [name]: value,
            dirtyFields: [...values.dirtyFields, name],
        }));
    };
    const handleSubmit = async () => {
        try {
            const response = await axios.post(
                urlUserCreatePassword,
                {
                    password: inputs.passwordNew,
                    passwordAgain: inputs.passwordNewAgain,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status == 200) {
                setStatusCode(response.status);
                setSave(false);
            }
        } catch (err) {
            setStatusCode(err.response.status);
            setSave(false);
        }
    };
    useEffect(() => {
        console.log("statusCode", statusCode);
        if (statusCode === 200) {
            setTimeout(() => {
                navigate("/", { replace: true });
            }, 5000);
        }
    }, [statusCode]);
    useEffect(() => {
        if (inputs.dirtyFields.length > 0) {
            setSave(true);
        }
    }, [inputs.dirtyFields]);
    const exitHandler = () => {
        setStatusCode(700);
    };
    useEffect(() => {
        let w = window.innerWidth;
        if (w < 767) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [mobile]);
    return (
        <Wrapper>
            {statusCode === 200 ? (
                <Overlay height={mobile ? "180": "163.8"} padding="28.8px">
                    <WrapperOverlay>
                        <HeaderOverlay>
                            <Exit onClick={exitHandler}>
                                <Image src={exit} />
                            </Exit>
                            <TextHeadingOverlay>
                                Konto skapas
                            </TextHeadingOverlay>
                        </HeaderOverlay>
                        <ContentOverlay>
                            <TextParagraphOverlay>
                                Ditt konto skapas, du kommer strax omdirigeras
                                till startsidan.
                            </TextParagraphOverlay>
                        </ContentOverlay>
                    </WrapperOverlay>
                </Overlay>
            ) : null}
            <StatusContainer>
                <ButtonContainer
                    start={1}
                    end={2}
                    justify="flex-start"
                ></ButtonContainer>
                <LogoContainer>
                    <Logo src={`${logo}`} />
                </LogoContainer>
                <ButtonContainer
                    start={3}
                    end={4}
                    justify="flex-end"
                ></ButtonContainer>
            </StatusContainer>
            <NavContainer>
                <Nav>
                    <Menu>
                        <Heading>
                            {inputs.firstName + " " + inputs.lastName}
                        </Heading>
                    </Menu>
                    <RegContainer></RegContainer>
                </Nav>
            </NavContainer>
            <WrapperContainer>
                <Section height="428.8px" top="129.6px">
                    <UnderHeading>Konto</UnderHeading>
                    <InputContainer>
                        <Label top="21.6px">E-post</Label>
                        <EmailInput
                            type="text"
                            name="email"
                            value={inputs.email || ""}
                            onChange={handleChange}
                            readOnly={true}
                        />
                        <Label top="14.4px">Nytt lösenord</Label>
                        <Input
                            type="password"
                            name="passwordNew"
                            value={inputs.passwordNew || ""}
                            onChange={handleChange}
                            statusCode={statusCode}
                        />
                        <Label top="14.4px">Nytt lösenord (Repetera)</Label>
                        <Input
                            type="password"
                            name="passwordNewAgain"
                            value={inputs.passwordNewAgain || ""}
                            onChange={handleChange}
                            statusCode={statusCode}
                        />
                        <SaveBtn
                            onClick={
                                save
                                    ? handleSubmit
                                    : (e) => console.log("no save")
                            }
                            hasChanged={save}
                        >
                            Spara
                        </SaveBtn>
                    </InputContainer>
                </Section>
                <PaddingBottom />
            </WrapperContainer>
        </Wrapper>
    );
};

export default Password;
