import React from "react";

import styled from "styled-components";
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #f3f3f8;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f8;
    width: 60vh;
    height: 20vh;
    border-radius: 15px;
    box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.4);
`;
const Heading = styled.h1`
    margin: 0px 0px 0px 0px;
    text-align: center;
    color: #66659c;
    font-size: 33.75px;
    font-weight: 700;
`;

const Paragraph = styled.p`
    margin: 0px 0px 0px 0px;
    text-align: center;
    padding: 0px;
    color: #4d4d4d;
    font-size: 19.805625px;
    font-weight: 400;
`;
const ErrorPage = ({heading, message}) => {
    return <Wrapper>
        <Content>
            <Heading>{heading}</Heading>
            <Paragraph>
                {message}
            </Paragraph>
        </Content>
    </Wrapper>;
};
export default ErrorPage;
