import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    height: 854.8125px;
    @media (max-width: 767px) {
        padding-left: 18.63px;
        padding-right: 18.63px;
        height: auto;
        margin-top: 160px;
    }
    @media (min-width: 767px) and (max-width: 1195px){
        height: auto;
        padding-left: 28.8px;
        padding-right: 28.8px;
    }
`;
const TextHeading = styled.h2`
    padding: 0px 0px 0px 0px;
    margin: 168.75px 0px 0px 28.8px;
    color: #66659c;
    font-size: 33.75px;
    font-weight: 700;
    @media (max-width: 767px) {
        font-size: 25.62px;
        margin: 0px;
    }
    @media (min-width: 767px) and (max-width: 1195px){
        margin: 0px;
    }
`;
const TextParagraphLeading = styled.p`
    margin-top: 28px;
    margin-left: 28.8px;
    margin-right: 28.8px;
    margin-bottom: 28.8px;
    padding: 0px;
    color: #4d4d4d;
    font-size: 19.805625px;
    font-weight: 400;
    height: auto;
    @media (max-width: 767px) {
        font-size: 16.86562px;
        margin-top: 18.63px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 18.63px;
    }
    @media (min-width: 767px) and (max-width: 1195px){
        margin-top: 28.8px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 28.8px;
    }
`;
const TextParagraph = styled.p`
    margin: 0px;
    padding: 0px;
    color: #4d4d4d;
    font-size: 17.510625px;
    font-weight: 400;
    height: auto;
    @media (max-width: 767px) {
        font-size: 14.510625px;
    }
`;
const RightColumn = styled.div`
    width: 50%;
    margin-left: 28.8px;
    padding-right: 28.8px;
    @media (max-width: 767px) {
        width: 100%;
        margin-left: 0px;
        margin-top: 18.63px;
        padding-right: 0px;
    }
    @media (min-width: 767px) and (max-width: 1195px){
        width: 100%;
        margin-left: 0px;
        margin-top: 28.8px;
        padding-right: 0px;
    }
`;
const LeftColumn = styled.div`
    width: 50%;
    margin-left: 28.8px;
    @media (max-width: 767px) {
        width: 100%;
        margin-left: 0px;
    }
    @media (min-width: 767px) and (max-width: 1195px){
        width: 100%;
        margin-left: 0px;
    }
`;
const Column = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 28.8px;
    text-align: justify;
    @media (max-width: 767px) {
        flex-direction: column;
        margin-top: 9.3px;
        margin-bottom: 9.3px;
    }
    @media (min-width: 767px) and (max-width: 1195px){
        flex-direction: column;
        margin-top: 14.4px;
        margin-bottom: 14.4px;
    }
`;
const ColumnContainer = styled.div`
    text-align: justify;
`;
const Button = styled.div`
    font-size: 15.94375px;
    cursor: pointer;
    color: #66659c;
    margin-top: 9.3px;
    padding-bottom: 18.63px;
    @media (min-width: 767px) and (max-width: 1195px){
        padding-top:14.4px;
        padding-bottom: 28.8px;
    }
`;
const Background = () => {
    const [mobile, setMobile] = useState(false);
    const [isShow, setIsShow] = useState(false);
    useEffect(() => {
        console.log("mobile", mobile);
        let w = window.innerWidth;
        if (w <= 1195) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [mobile, isShow]);
    return (
        <Wrapper>
            <TextHeading>Bakgrund</TextHeading>
            <ColumnContainer>
                <TextParagraphLeading>
                    Danzpunktens vision är att föra dansen i takt med tiden. Vi
                    tror att genom att överbrygga digitala trösklar kan vi verka
                    för att sprida dansglädje till fler. En modern digital
                    infrastruktur behöver komma på plats som sammanbinder
                    dansvärldens aktörer med morgondagens utövare. Vårt
                    långsiktiga mål är att Danzpunkten ska bli den samlingspunkt
                    där dansare, arrangörer och musiker möts. Vi är övertygade
                    om att ett digitalt ekosystem skulle medföra stora värden
                    för befintliga och framtida dansentusiaster.
                </TextParagraphLeading>
                {mobile ? isShow ? null : (
                    <Button onClick={(e) => setIsShow((prev) => !prev)}>
                        Visa mer
                    </Button>
                ) : null}
                {mobile ? (
                    isShow ? (
                        <>
                            <Column>
                                <LeftColumn>
                                    <TextParagraph>
                                        För mer än ett sekel sedan, när
                                        spelmännen kom till byn, gick folk man
                                        ur huse för att roa sig på dansbanan.
                                        Folkparker, bygdegårdar och
                                        föreningslokaler fungerade som lokala
                                        samlingsplatser där dans kombinerades
                                        med olika sociala aktiviteter. I och med
                                        personbilens genomslag minskade
                                        avståndet mellan byarna och människor
                                        från kringliggande orter började delta i
                                        festligheterna. Dansens syfte och
                                        karaktär förändrades och socialdansen
                                        blev det nya.
                                    </TextParagraph>
                                    <TextParagraph>
                                        Det svenska musikundret föddes ur de
                                        kringresande dansbandsmusikerna som
                                        provspelade ny musik och spred sina
                                        låtar till en hänförd publikskara. På
                                        Folkets Hus och danslogar umgicks
                                        besökande dansare över
                                        generationsgränserna, nya bekantskaper
                                        formades och när stjärnorna stod rätt,
                                        fann två själar en livspartner.
                                        Dansbandskulturen nådde sin kulmen i
                                        slutet av 70-talet då tillförseln av nya
                                        dansare började avta då diskotek med den
                                        moderna discomusiken var på tapeten. Men
                                        trots nya trender och vikande påfyllnad
                                        av yngre dansare har kulturen bestått.
                                    </TextParagraph>
                                    <TextParagraph>
                                        Under år 2020 blev virussjukdomen
                                        Covid-19 klassad som en pandemi.
                                    </TextParagraph>
                                </LeftColumn>
                                <RightColumn>
                                    <TextParagraph>
                                        För att minska på smittspridningen var
                                        social distansering det centrala
                                        budskapet. Dansen drabbades hårt som
                                        fick stänga ned när antal personer i
                                        sammanslutningar begränsades. När
                                        restriktionerna väl hävts och tillvaron
                                        successivt började återgå till det
                                        normala stod nästa kris vid dörren. Det
                                        länge fredade Europa befann sig i krig
                                        och priser på tjänster och varor sköt i
                                        höjden. Socialdansen med dess
                                        kringliggande näringar fick återigen
                                        uppleva ett bakslag som redan hade en
                                        ansträngd ekonomi i sviterna av
                                        Covid-19.
                                    </TextParagraph>
                                    <TextParagraph>
                                        Dansbandskulturen är djupt rotad i den
                                        svenska folksjälen och är hjärtat i
                                        kulturlivet i många landsbygdsorter.
                                        Varje sommar anordnar lokala eldsjälar
                                        sommardanser för att stärka
                                        idrottsföreningens ekonomi, hängivna
                                        dansentreprenörer arrangerar
                                        dansevenemang och sammanfogar dansare
                                        från hela landet under större dansmaror.
                                        Den svenska dansbandstraditionen är
                                        således unik och bör betraktas som en av
                                        våra kulturskatter. Att gå på dans en
                                        varm sommarkväll, till en dansbana
                                        belägen i ett vackert omgivande
                                        landskap, är en upplevelse värd att
                                        förmedla. Dansen tar fram det bästa i
                                        oss och fler skulle finna nöje i att
                                        upptäcka den.
                                    </TextParagraph>
                                </RightColumn>
                            </Column>
                            {mobile ? (
                                isShow ? (
                                    <Button
                                        onClick={(e) =>
                                            setIsShow((prev) => !prev)
                                        }
                                    >
                                        Visa mindre
                                    </Button>
                                ) : null
                            ) : null}
                        </>
                    ) : null
                ) : (
                    <Column>
                        <LeftColumn>
                            <TextParagraph>
                                För mer än ett sekel sedan, när spelmännen kom
                                till byn, gick folk man ur huse för att roa sig
                                på dansbanan. Folkparker, bygdegårdar och
                                föreningslokaler fungerade som lokala
                                samlingsplatser där dans kombinerades med olika
                                sociala aktiviteter. I och med personbilens
                                genomslag minskade avståndet mellan byarna och
                                människor från kringliggande orter började delta
                                i festligheterna. Dansens syfte och karaktär
                                förändrades och socialdansen blev det nya.
                            </TextParagraph>
                            <TextParagraph>
                                Det svenska musikundret föddes ur de
                                kringresande dansbandsmusikerna som provspelade
                                ny musik och spred sina låtar till en hänförd
                                publikskara. På Folkets Hus och danslogar
                                umgicks besökande dansare över
                                generationsgränserna, nya bekantskaper formades
                                och när stjärnorna stod rätt, fann två själar en
                                livspartner. Dansbandskulturen nådde sin kulmen
                                i slutet av 70-talet då tillförseln av nya
                                dansare började avta då diskotek med den moderna
                                discomusiken var på tapeten. Men trots nya
                                trender och vikande påfyllnad av yngre dansare
                                har kulturen bestått.
                            </TextParagraph>
                            <TextParagraph>
                                Under år 2020 blev virussjukdomen Covid-19
                                klassad som en pandemi.
                            </TextParagraph>
                        </LeftColumn>
                        <RightColumn>
                            <TextParagraph>
                                För att minska på smittspridningen var social
                                distansering det centrala budskapet. Dansen
                                drabbades hårt som fick stänga ned när antal
                                personer i sammanslutningar begränsades. När
                                restriktionerna väl hävts och tillvaron
                                successivt började återgå till det normala stod
                                nästa kris vid dörren. Det länge fredade Europa
                                befann sig i krig och priser på tjänster och
                                varor sköt i höjden. Socialdansen med dess
                                kringliggande näringar fick återigen uppleva ett
                                bakslag som redan hade en ansträngd ekonomi i
                                sviterna av Covid-19.
                            </TextParagraph>
                            <TextParagraph>
                                Dansbandskulturen är djupt rotad i den svenska
                                folksjälen och är hjärtat i kulturlivet i många
                                landsbygdsorter. Varje sommar anordnar lokala
                                eldsjälar sommardanser för att stärka
                                idrottsföreningens ekonomi, hängivna
                                dansentreprenörer arrangerar dansevenemang och
                                sammanfogar dansare från hela landet under
                                större dansmaror. Den svenska
                                dansbandstraditionen är således unik och bör
                                betraktas som en av våra kulturskatter. Att gå
                                på dans en varm sommarkväll, till en dansbana
                                belägen i ett vackert omgivande landskap, är en
                                upplevelse värd att förmedla. Dansen tar fram
                                det bästa i oss och fler skulle finna nöje i att
                                upptäcka den.
                            </TextParagraph>
                        </RightColumn>
                    </Column>
                )}
            </ColumnContainer>
        </Wrapper>
    );
};
export default Background;
