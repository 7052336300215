import React, { useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(51, 51, 51, 0.2);
    position: fixed;
    z-index: 10;
    padding-left: ${(props) => props.padding};
    padding-right: ${(props) => props.padding};
`;
const Box = styled.div`
    max-width: 491.0625px;
    width: 100%;
    height: ${(props) => props.height + "px"};
    background-color: #34326a;
    border-radius: 15px;
    box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.7);
`;
const Overlay = ({ height, children, padding }) => {
    return (
        <Wrapper padding={padding}>
            <Box height={height}>{children}</Box>
        </Wrapper>
    );
};
export default Overlay;
